import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import firebase from "firebase/app"
import moment from "moment"
import {useEffect, useState} from "react"
import {Button, Card, Col, Container, Dropdown, Row} from "react-bootstrap"
import Dialog from 'react-bootstrap-dialog'
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap"
import {useHistory} from "react-router-dom"
import {clearCacheUserProfiles, UserState} from "src/models/UserModel"
import {deletePuzzle} from "src/utils/api"
import blackStone from "../../assets/img/black.png"
import whiteStone from "../../assets/img/white.png"
import {Transformation} from "../CloudinaryImage"
import {If} from "../Div"
import {User} from "../Model"
import PuzzleGoban from "../ReactGoban/PuzzleGoban"
import AvatarImage from "./AvatarImage"

interface PuzzlePostProps {
  item: any
}

export default function PuzzlePost({ item }: PuzzlePostProps): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const [numberAnswerUser, setNumberAnswerUser] = useState<number>(4)
  const isOwner: boolean = firebase.auth().currentUser?.uid === item?.uid
  const [answered, setAnswered] = useState<boolean>(false)
  let dialog:any

  useEffect(() => {
    if (!item) {
      return
    }
    const user = firebase.auth().currentUser
    if (item?.puzzle?.puzzleAnswers?.find((answer: any) => answer.uid === user?.uid)) {
      setAnswered(true)
    }
  }, [item])

  const handleDelete = () => {
    dialog.show({
      body: t('Are you sure you wish to delete this puzzle?'),
      bsSize: 'medium',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(async () => {
          await deletePuzzle(item?.puzzle?.id)
          clearCacheUserProfiles(item?.puzzle?.uid)
          history.push('/puzzles')
        })
      ],
    })
  }

  const handleShare = () => {
    // eslint-disable-next-line no-restricted-globals
    const url = (new URL('/puzzles/' + item?.puzzle?.id, location.href)).href
    const handleCopyText = () => {
      navigator.clipboard.writeText(url)
    }
    dialog.show({
      body: (
        <div>
          <div className="mb-3">{t('Copy the link below to share')}</div>
          <Card className="bg-dark text-light">
            <div className="p-2 d-flex justify-content-between align-items-center">
              <div className="text-break">{url}</div>
              <Button variant="default" className="text-primary text-nowrap ml-3" onClick={handleCopyText}>{t('Copy')}</Button>
            </div>
          </Card>
        </div>
      ),
      bsSize: 'medium',
      actions: [
        Dialog.OKAction()
      ],
    })
  }

  return (
    <Card className="Post-Card mb-3">
      <User data={item.user}>
        {(user: UserState) => (
          <Card.Body className="Post-Header d-flex flex-row">
            <div className="Avatar mr-3">
              <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                <AvatarImage className="w-100 rounded-circle" user={user}>
                  <Transformation width="80" height="80" gravity="auto" crop="fill" />
                </AvatarImage>
              </LinkContainer>
            </div>
            <div className="Username d-flex justify-content-between align-items-center w-100">
              <div>
                <div>
                  <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                    <a href={"/users/" + (user?.username || user.uid)} className="UserLink">{user.displayName}</a>
                  </LinkContainer>
                </div>
                <div className="text-muted">
                  {moment(item.createdAt).fromNow()}
                </div>
              </div>
              <If show={!!item?.puzzle?.id}>
                <Dropdown className="More-DropDown" alignRight>
                  <Dropdown.Toggle className="rounded-circle px-2 py-1" variant="default">
                    <FontAwesomeIcon icon="ellipsis-h" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <If show={isOwner}>
                      <LinkContainer to={"/puzzles/edit/" + item?.puzzle?.id}>
                        <Dropdown.Item>{t('Edit Puzzle')}</Dropdown.Item>
                      </LinkContainer>
                      <Dropdown.Item onClick={handleDelete}>{t('Delete Puzzle')}</Dropdown.Item>
                    </If>
                    <Dropdown.Item onClick={handleShare}>{t('Share')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </If>
            </div>
          </Card.Body>
        )}
      </User>
      <If className="mt-2" show={!!item?.puzzle?.puzzleType}>
        <Card.Body className="pt-0 pb-3">
          {t(item?.puzzle?.puzzleType)}
        </Card.Body>
      </If>
      <LinkContainer to={"/puzzles/" + item?.puzzle?.id}>
        <Container className="Post-Content text-light position-relative">
          <Row className="my-3">
            <Col sm={2} className="d-flex flex-sm-column justify-content-between align-items-center py-sm-3">
              <img className="Big-Stone border rounded-circle bg-light border-light" src={item?.puzzle?.initialPlayer === 'black' ? blackStone : whiteStone} alt="" />
              <If hide={answered}><FontAwesomeIcon className="Star text-secondary" icon="question" /></If>
              <If show={answered}><FontAwesomeIcon className="Star text-success" icon="check" /></If>
            </Col>
            <Col sm={8} className="my-3 my-sm-0 p-sm-0">
              <PuzzleGoban puzzle={item?.puzzle} interactive={false} />
            </Col>
            <Col sm={2} className="d-flex flex-sm-column justify-content-sm-start justify-content-center align-items-center py-sm-3">
              {item?.puzzle?.level >= 1 && <FontAwesomeIcon className={`Star mx-2 mx-sm-0 my-sm-1 ${item?.puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
              {item?.puzzle?.level >= 2 && <FontAwesomeIcon className={`Star mx-2 mx-sm-0 my-sm-1 ${item?.puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
              {item?.puzzle?.level >= 3 && <FontAwesomeIcon className={`Star mx-2 mx-sm-0 my-sm-1 ${item?.puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
              {item?.puzzle?.level >= 4 && <FontAwesomeIcon className={`Star mx-2 mx-sm-0 my-sm-1 ${item?.puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
              {item?.puzzle?.level >= 5 && <FontAwesomeIcon className={`Star mx-2 mx-sm-0 my-sm-1 ${item?.puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
            </Col>
          </Row>
        </Container>
      </LinkContainer>
      {!!item?.puzzle?.puzzleAnswers?.length && <Card.Body className="Puzzle-Members">
        <h6 className="text-muted">{t('Member who answered correctly')}</h6>
        <Row>
          {item?.puzzle?.puzzleAnswers?.map((u: any, index: number) => index < numberAnswerUser && (
            <User key={u.uid} data={u.user}>
              {(user: UserState) => (
                <Col md={6} className="d-flex flex-row align-items-center mt-1">
                  <div className="Avatar mr-3">
                    <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                      <AvatarImage className="w-100 rounded-circle" user={user}>
                        <Transformation width="80" height="80" gravity="auto" crop="fill" />
                      </AvatarImage>
                    </LinkContainer>
                  </div>
                  <div className="Username">
                    <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                      <a href={"/users/" + (user?.username || user.uid)} className="UserLink">{user.displayName}</a>
                    </LinkContainer>
                  </div>
                </Col>
              )}
            </User>
          ))}
          {numberAnswerUser < item?.puzzle?.puzzleAnswers?.length && (<Col md={12} className="mt-3">
            <Button onClick={() => setNumberAnswerUser(item?.puzzle?.puzzleAnswers?.length)} size="sm" variant="light" className="text-muted" block>
              <FontAwesomeIcon className="mr-2" icon="eye" />
              {t('Show more...')}
            </Button>
          </Col>)}
        </Row>
      </Card.Body>}
      <Dialog ref={(el) => { dialog = el }} />
    </Card>
  )
}
