import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from 'react-router-dom'
import Feed from 'src/components/Feed/Feed'
import { CreatePost, TopReviewer, UserInfo, WelcomeGuest } from '../../components/Feed'
import SideBar from './SideBar'

function Home() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const filters = {
    '/': { where: { createdAt: { lte: new Date() } }, order: 'createdAt DESC' },
    '/feed/reviews': { where: { type: 'review', createdAt: { lte: new Date() } }, order: 'createdAt DESC' },
    '/feed/kifu': { where: { type: 'kifu', createdAt: { lte: new Date() } }, order: 'createdAt DESC' },
    '/feed/puzzles': { where: { type: 'puzzle', createdAt: { lte: new Date() } }, order: 'createdAt DESC' },
  }
  const viewType = location?.pathname || '/'

  return (
    <Container className="my-3 Home">
      <Row>
        <Col className="d-none d-lg-block">
          <div className="sticky-top">
            <UserInfo />
            <WelcomeGuest />
            <SideBar />
          </div>
        </Col>
        <Col md={8} lg={6}>
          <CreatePost onDone={kifu => history.push('/kifu/' + kifu.id)} />
          <div className="d-lg-none">
            <SideBar selectIcon="eye" />
          </div>
          <Feed filter={filters[viewType]} />
        </Col>
        <Col className="d-none d-md-block">
          <div className="sticky-top">
            <h4>{t('Top Reviewers')}</h4>
            <TopReviewer />
            <h4>{t('Tham khảo')}</h4>
            <ListGroup className="mb-3">
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://vietnamchess.vn" target="_blank" rel="noreferrer">Liên đoàn cờ Việt Nam</a>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://www.facebook.com/groups/229629630634" target="_blank" rel="noreferrer">Cộng đồng cờ vây VN</a>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://blogcovay.com" target="_blank" rel="noreferrer">Blog Cờ Vây</a>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
