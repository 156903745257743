import firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { CurrentUser, UserContext } from 'src/contexts/UserContext'
import { getMeProfiles } from 'src/utils/api'
import Header from '../../components/Header'
import Category from '../Category'
import FeedDetails from '../Feed/Details'
import Play from '../Game/Play'
import Home from '../Home'
import Login from '../Login'
import ForgotPassword from '../Login/ForgotPassword'
import {
  Create as PuzzleCreate,
  Details as PuzzleDetails,
  Listing as PuzzleListing
} from '../Puzzle'
import Register from '../Register'
import Review from '../Review'
import Kifu from '../Review/Kifu'
import ReviewListing from '../Review/Listing'
import Setting from '../User/Setting'
import UserWall from '../User/UserWall'
import Error from './Error'
import NotFound from './NotFound'

function App() {
  const [user, setUser] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [firebaseLoaded, setFirebaseLoaded] = useState(false)

  useEffect(() => {
    let isSubscribed = true
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!isSubscribed) {
        return
      }
      setUser(user)
      setFirebaseLoaded(true)
      if (user?.uid) {
        const profile = await getMeProfiles()
        if (!isSubscribed) {
          return
        }
        setCurrentUser(CurrentUser(profile))
      } else {
        setCurrentUser(null)
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
      {...rest}
      render={(props) => user !== null
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: { from: props.location }}} />}
    />
  )

  return firebaseLoaded && (
    <Router>
      <UserContext.Provider value={currentUser}>
        <Header/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/feed/reviews" component={Home} />
          <Route exact path="/feed/kifu" component={Home} />
          <Route exact path="/feed/puzzles" component={Home} />
          <Route exact path="/categories/:alias" component={Category} />
          <Route path="/feed/details/:feedId" component={FeedDetails} />
          <Route path="/play" component={Play} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/register" component={Register} />
          <Route exact path="/reviews" component={ReviewListing} />
          <Route path="/kifu/:kifuId" component={Kifu} />
          <Route path="/reviews/:reviewId" component={Review} />
          <Route path="/users/:username" component={UserWall} />
          <PrivateRoute exact path="/settings" component={Setting} />
          <PrivateRoute exact path="/puzzles/create" component={PuzzleCreate} />
          <PrivateRoute path="/puzzles/edit/:puzzleId" component={PuzzleCreate} />
          <Route exact path="/puzzles/:puzzleId" component={PuzzleDetails} />
          <Route exact path="/puzzles" component={PuzzleListing} />
          <Route exact path="/error" component={Error} />
          <Route component={NotFound} />
        </Switch>
      </UserContext.Provider>
    </Router>
  )
}

export default App
