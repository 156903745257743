import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import { useState, useEffect } from 'react'
import { Container, Table, Button, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { getUserRating } from '../../components/ReactGoban/utils'

function Listing() {
  let update = moment().unix()
  const limit = 50
  const [loaded, setLoaded] = useState(false)
  const [games, setGames] = useState([])
  const [isListOver, setIsListOver] = useState(false)

  useEffect(() => {
    const docRef = firebase.firestore().collection('kifu')
      .where('status', '==', 'review').orderBy('createdAt', 'desc').limit(limit)

    docRef.get().then(async (snapshot) => {
      setGames(snapshot.docs)
      setLoaded(true)
    })
  }, [update])

  const handleLoadMore = e => {
    e.preventDefault()
    if (!games.length) {
      return
    }

    const lastId = games[games.length - 1]
    const docRef = firebase.firestore().collection('kifu')
      .where('status', '==', 'new')
      .orderBy('createdAt', 'desc').startAfter(lastId).limit(limit)

    docRef.get().then(async (snapshot) => {
      if (snapshot.size < limit) {
        setIsListOver(true)
      }
      setGames([
        ...games,
        ...snapshot.docs
      ])
    })
  }

  return (
    <Container className="my-3">
      <Row className="mb-2">
        <Col lg={6}>
          <h2>Kifu Reviewed</h2>
        </Col>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Black</th>
            <th>White</th>
            <th>Size / KOMI / Handicap</th>
            <th>Started</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {games.length ? games.map(doc => ({ ...doc.data(), id: doc.id })).map(
            (item, index) => (
              <tr key={index}>
                <td>
                  <LinkContainer to={'reviews/' + item.id}>
                    <Button size="sm">View</Button>
                  </LinkContainer>
                </td>
                <td>{item.user.displayName}</td>
                <td>{item.info?.players?.black?.username}[{getUserRating(item.info?.players?.black || {}).rank_label}]</td>
                <td>{item.info?.players?.white?.username}[{getUserRating(item.info?.players?.white || {}).rank_label}]</td>
                <td>{item.info?.width}x{item.info?.height} / {parseFloat(item.info?.komi)} / H{parseFloat(item.info?.handicap)}</td>
                <td>{moment(item.info?.started).format('YYYY-MM-DD HH:mm:ss')}</td>
                {item.info?.ended && <td>{item.info?.black_lost ? 'W' : 'B'}+{item.info?.outcome}</td>}
                {!item.info?.ended && <td>Playing...</td>}
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={7}>{loaded ? 'No kifu' : 'Loading...'}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {!!games.length && games.length >= limit && !isListOver && <div className="text-right">
        <Button size="sm" variant="link" onClick={handleLoadMore}>Load more...</Button>
      </div>}
    </Container>
  )
}

export default Listing
