import firebase from 'firebase/app'
import * as Yup from 'yup'
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import SubmitButton from 'src/components/SubmitButton';
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('must be a valid email').required('is a required field'),
})

function ForgotPassword(props) {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({});

  const handleSetFormData = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err) {
      const errors = {}
      for (const error of err.inner) {
        error.path = '/' + error.path
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      await firebase.auth().sendPasswordResetEmail(formData.email)
      toast(t('The password reset link has been sent to your email'), { type: 'success', autoClose: 8000 })
    } catch (err) {
      return setErrors({
        other: {
          path: 'other',
          message: err?.response?.data?.error?.message ?? err.message
        }
      })
    }
  };

  return (
    <Container className="mt-5 md-5 login-container">
      <Row className="login-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="login-card">
            <Card.Body className="p-0">
              <Row>
                <Col className="d-none d-lg-block login-bg">
                </Col>
                <Col className="p-5">
                  <h2>{t('Forgot password?')}</h2>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>{t('Email address')}</Form.Label>
                      <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t("Email address")} />
                      {errors?.['/email']?.message && (<Form.Text className="text-danger">{t(errors?.['/email']?.path)} {t(errors?.['/email']?.message)}</Form.Text>)}
                      {errors?.other?.message && (<Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text>)}
                    </Form.Group>
                    <SubmitButton variant="success" type="submit" block onClick={handleSubmit}>
                      {t('Submit')}
                    </SubmitButton>
                    <hr/>
                    <div className="text-center">
                      <Link to="/login">{t('Already have an account? Login!')}</Link>
                    </div>
                    <div className="text-center">
                      <Link to="/register">{t('Create an Account')}</Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  )
}

export default ForgotPassword;
