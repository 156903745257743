import React from 'react'

export const UserContext = React.createContext<any>(null)

export const CurrentUser = (profile: any) => {
  const checkRole = (roles: string[], ownerId?: string | number, resource?: string) => {
    if (roles.indexOf('owner') >= 0 && profile.uid === ownerId) {
      return true
    }
    const permissions = profile?.permissions ?? []
    if (
      roles.indexOf(profile?.role) >= 0 &&
      (!resource ||
        permissions.indexOf('*') >= 0 ||
        permissions.indexOf(resource) >= 0)
    ) {
      return true
    }
    return false
  }

  return {
    ...profile,
    checkRole
  }
}
