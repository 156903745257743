import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useCallback, useContext, useEffect, useRef, useState} from "react"
import {Button, Card, Col, Container, Dropdown, Row} from "react-bootstrap"
import Dialog from 'react-bootstrap-dialog'
import {XLg} from "react-bootstrap-icons"
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {Transformation} from "src/components/CloudinaryImage"
import Div, {If} from "src/components/Div"
import AvatarImage from "src/components/Feed/AvatarImage"
import {User} from "src/components/Model"
import PuzzleGoban from "src/components/ReactGoban/PuzzleGoban"
import {UserContext} from 'src/contexts/UserContext'
import {clearCacheUserProfiles, UserState} from "src/models/UserModel"
import {deletePuzzle, getPuzzle, listPuzzles, puzzleAddCorrectAnswerUsers, updatePuzzleOffical} from "src/utils/api"
import blackStone from "../../assets/img/black.png"
import whiteStone from "../../assets/img/white.png"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Details(): JSX.Element {
  const { t } = useTranslation()
  const { puzzleId } = useParams<any>()
  const query: any = useQuery()
  const refGoban = useRef<any>(null)
  const currentUser: any = useContext(UserContext)
  const [answer, setAnswer] = useState<string>('')
  const [puzzle, setPuzzle] = useState<any>(null)
  const [numberAnswerUser, setNumberAnswerUser] = useState<number>(4)
  const [view, setView] = useState<string>(query.get('view') ?? 'new')
  const [difficulty, setDifficulty] = useState<string>('')
  const [order, setOrder] = useState<string>('')
  const [nextId, setNextId] = useState<string>('')
  const [prevId, setPrevId] = useState<string>('')
  const [answered, setAnswered] = useState<boolean>(false)
  const history = useHistory()
  let dialog:any

  useEffect(() => {
    let isSubscribed = true
    setAnswer('')
    getPuzzle(puzzleId, { filter: { include: [
      { relation: 'user' },
      {
        relation: 'puzzleAnswers',
        scope: {
          include: [{
            relation: 'user'
          }]
        }
      }
    ] } }).then(data => {
      if (!isSubscribed) {
        return
      }
      setPuzzle(data)
      if (data?.puzzleAnswers?.find((answer: any) => answer.uid === currentUser?.uid)) {
        setAnswered(true)
      } else {
        setAnswered(false)
      }
    }).catch(err => {
      if (err?.response?.status === 404) {
        return history.replace('/404')
      }
      return history.replace('/error')
    })
    return () => {
      isSubscribed = false
    }
  }, [currentUser?.uid, history, puzzleId])


  useEffect(() => {
    setDifficulty(query.get('difficulty') ?? '')
    setOrder(query.get('order') ?? '')
    setView(query.get('view') ?? 'new')
  }, [query])

  // get next link
  useEffect(() => {
    if (!puzzle) {
      return
    }
    let isSubscribed = true
    const params: any = {
      filter: {
        where: {
          official: view === 'official',
          createdAt: {
            [order === 'oldest' ? 'gt' : 'lt']: puzzle?.createdAt
          }
        },
        fields: ['id'],
        limit: 1,
        order: order === 'oldest' ? 'createdAt ASC' : 'createdAt DESC'
      }
    }
    if (difficulty) {
      params.filter.where = {
        ...params.filter.where,
        level: difficulty,
      }
    }
    listPuzzles(params).then(data => {
      if (!isSubscribed) {
        return
      }
      setNextId(data?.[0]?.id)
    })
    return () => {
      isSubscribed = false
    }
  }, [difficulty, order, puzzle, view])

  // get prev link
  useEffect(() => {
    if (!puzzle) {
      return
    }
    let isSubscribed = true
    const params: any = {
      filter: {
        where: {
          official: view === 'official',
          createdAt: {
            [order !== 'oldest' ? 'gt' : 'lt']: puzzle?.createdAt
          }
        },
        fields: ['id'],
        limit: 1,
        order: order !== 'oldest' ? 'createdAt ASC' : 'createdAt DESC'
      }
    }
    if (difficulty) {
      params.filter.where = {
        ...params.filter.where,
        level: difficulty,
      }
    }
    listPuzzles(params).then(data => {
      if (!isSubscribed) {
        return
      }
      setPrevId(data?.[0]?.id)
    })
    return () => {
      isSubscribed = false
    }
  }, [difficulty, order, puzzle, view])

  const handleGoPrev = (e: any) => {
    e.preventDefault()
    history.push({
      pathname: '/puzzles/' + prevId,
      search: query.toString()
    })
  }
  const handleGoNext = (e: any) => {
    e.preventDefault()
    history.push({
      pathname: '/puzzles/' + nextId,
      search: query.toString()
    })
  }

  const handleSetOfficial = () => {
    dialog.show({
      body: t('Are you sure you wish to set official this puzzle?'),
      bsSize: 'medium',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(async () => {
          await updatePuzzleOffical(puzzle?.id, {})
          history.push({
            pathname: '/puzzles',
            search: 'view=official'
          })
        })
      ],
    })
  }

  const handleDelete = () => {
    dialog.show({
      body: t('Are you sure you wish to delete this puzzle?'),
      bsSize: 'medium',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(async () => {
          await deletePuzzle(puzzle?.id)
          clearCacheUserProfiles(puzzle?.uid)
          history.push('/puzzles')
        })
      ],
    })
  }

  const handleReset = () => {
    refGoban.current.showFirst()
    setAnswer('')
  }

  const handleUndo = () => {
    refGoban.current.showPrevious()
    setAnswer('')
  }

  const handleWrongAnswer = useCallback(async () => {
    setAnswer('wrong')
  }, [])

  const handleCorrectAnswer = useCallback(async () => {
    setAnswer('correct')
    if (currentUser?.uid && puzzle?.id) {
      await puzzleAddCorrectAnswerUsers(puzzle?.id)
    }
  }, [currentUser?.uid, puzzle?.id])

  useEffect(() => {
    if (!refGoban || !refGoban.current) {
      return
    }
    refGoban.current.on('puzzle-wrong-answer', handleWrongAnswer)
    refGoban.current.on('puzzle-correct-answer', handleCorrectAnswer)
  }, [handleCorrectAnswer, handleWrongAnswer, refGoban])

  const handleGobanStateChange = ({ moveText }: { moveText: string }) => {
    if (!refGoban?.current || !moveText) {
      return
    }
    refGoban?.current?.showMessage(moveText, Math.ceil(moveText.length / 80) * 5000)
  }

  return (
    <Div show={puzzle !== null}>
      <Container className="my-3">
        <Row className="mb-3">
          <Col className="d-flex justify-content-between">
            <If show={!!prevId}><Button variant="default" onClick={handleGoPrev}><FontAwesomeIcon icon="angle-left" /> {t('Previous')}</Button></If>
            <span></span>
            <If show={!!nextId}><Button variant="default" onClick={handleGoNext}>{t('Next')} <FontAwesomeIcon icon="angle-right" /></Button></If>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="mb-3">
            <Card className="overflow-hidden">
              <PuzzleGoban puzzle={puzzle} ref={refGoban} onStateChange={handleGobanStateChange} />
              <Card.Body className={"d-flex align-items-center justify-content-between p-2 " +
                (answer === 'correct' ? 'bg-success' : (answer === 'wrong' ? 'bg-danger' : 'bg-dark'))}>
                <Button variant="default" className="text-light" onClick={handleUndo}>
                  <FontAwesomeIcon icon="undo" /> {t('Undo')}
                </Button>
                <div className="d-flex align-items-center text-light">
                  <img className="Square-30 border rounded-circle mr-2 bg-light" src={puzzle?.initialPlayer === 'black' ? blackStone : whiteStone} alt="" />
                  <If show={!answer}><FontAwesomeIcon className="Font-Size-2em" icon="question" /></If>
                  <If show={answer === 'correct'}><FontAwesomeIcon className="Font-Size-2em" icon="check" /><span className="d-none d-sm-inline ml-2">{t('Correct!')}</span></If>
                  <If show={answer === 'wrong'}><XLg className="Font-Size-2em" /><span className="d-none d-sm-inline ml-2">{t('Incorrect!')}</span></If>
                </div>
                <Button variant="default" className="text-light" onClick={handleReset}>
                  <FontAwesomeIcon icon="recycle" /> {t('Reset')}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-3">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <User key={puzzle?.uid} data={puzzle?.user}>
                  {(user: UserState) => (
                    <div className="mr-3">
                      <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                        <div>
                          <AvatarImage className="Square-45 Cursor-Pointer rounded-circle" user={user}>
                            <Transformation width="80" height="80" gravity="auto" crop="fill" />
                          </AvatarImage>
                          <span className="Cursor-Pointer ml-2">{user?.displayName}</span>
                        </div>
                      </LinkContainer>
                    </div>
                  )}
                </User>
                <If show={currentUser?.checkRole(['admin', 'moderator', 'owner'], puzzle?.uid, 'puzzles')}>
                  <Dropdown className="More-DropDown" alignRight>
                    <Dropdown.Toggle className="rounded-circle px-2 py-1" variant="default">
                      <FontAwesomeIcon icon="ellipsis-h" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <If show={!puzzle?.official && currentUser?.checkRole(['admin', 'moderator'], undefined, 'puzzles')}>
                        <Dropdown.Item onClick={handleSetOfficial}>{t('Set official')}</Dropdown.Item>
                      </If>
                      <LinkContainer to={"/puzzles/edit/" + puzzle?.id}>
                        <Dropdown.Item>{t('Edit Puzzle')}</Dropdown.Item>
                      </LinkContainer>
                      <If show={!puzzle?.official}>
                        <Dropdown.Item onClick={handleDelete}>{t('Delete Puzzle')}</Dropdown.Item>
                      </If>
                    </Dropdown.Menu>
                  </Dropdown>
                </If>
              </Card.Header>
              <Card.Body className="position-relative">
                <div>
                  <span className="mr-2">{t('Difficulty')}:</span>
                  <span>
                    {puzzle?.level >= 1 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
                    {puzzle?.level >= 2 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
                    {puzzle?.level >= 3 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
                    {puzzle?.level >= 4 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
                    {puzzle?.level >= 5 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
                  </span>
                </div>
                <Div className="mt-2" show={!!puzzle?.name}>{puzzle?.name}</Div>
                <Div className="mt-2" show={!!puzzle?.puzzleType}>{t('Type')}: {t(puzzle?.puzzleType)}</Div>
                <Div className="mt-2" show={!!puzzle?.initialPlayer}>{t('Player color')}: {t(puzzle?.initialPlayer)}</Div>
                <Div className="mt-2" show={!!puzzle?.description}>{puzzle?.description}</Div>
                <Div className="Right-10 Top-10 position-absolute" show={answered}>
                  <FontAwesomeIcon className="Font-Size-2em text-success" icon="check" />
                </Div>
              </Card.Body>
              <Div show={!!puzzle?.puzzleAnswers?.length}>
                <Card.Body className="border-top">
                  <h6 className="text-muted">{t('Member who answered correctly')}</h6>
                  <Row>
                    {puzzle?.puzzleAnswers?.map((u: any, index: number) => index < numberAnswerUser && (
                      <User key={u.uid} data={u.user}>
                        {(user: UserState) => (
                          <Col md={6} className="d-flex flex-row align-items-center mt-1">
                            <div className="mr-3">
                              <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                                <AvatarImage className="Square-30 Cursor-Pointer rounded-circle" user={user}>
                                  <Transformation width="80" height="80" gravity="auto" crop="fill" />
                                </AvatarImage>
                              </LinkContainer>
                            </div>
                            <div className="Username">
                              <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                                <a href={"/users/" + (user?.username || user.uid)} className="text-dark">{user.displayName}</a>
                              </LinkContainer>
                            </div>
                          </Col>
                        )}
                      </User>
                    ))}
                    <If show={numberAnswerUser < puzzle?.puzzleAnswers?.length}>
                      <Col md={12} className="mt-3">
                        <Button onClick={() => setNumberAnswerUser(puzzle?.puzzleAnswers?.length)} size="sm" variant="light" className="text-muted" block>
                          <FontAwesomeIcon className="mr-2" icon="eye" />
                          {t('Show more...')}
                        </Button>
                      </Col>
                    </If>
                  </Row>
                </Card.Body>
              </Div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Dialog ref={(el) => { dialog = el }} />
    </Div>
  )
}
