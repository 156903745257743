import { useRef, useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, Button, Card, Modal, Form } from 'react-bootstrap'
import { GobanConfig, GobanModes } from 'src/libs/goban/src/GobanCore'
import { useHistory } from 'react-router-dom'
import { aiPlay } from 'src/utils/api'
import ReactGoban from '../../components/ReactGoban'
import ActionBar from '../../components/ReactGoban/ActionBar'
import Players from '../../components/ReactGoban/Players'
import AnalyzeButtonsBar from '../../components/ReactGoban/AnalyzeButtonsBar'
import './style.scss'

export default function Play(): JSX.Element {
  const history = useHistory()
  const refGoban = useRef<any>(null)
  const refMoveTreeContainer = useRef<any>(null)
  const [config, setConfig] = useState<GobanConfig>({})
  const [submitMode, setSubmitMode] = useState<boolean>(false)
  const [mode, setMode] = useState<GobanModes>('play')
  const [moveNumber, setMoveNumber] = useState<number>(0)
  const [players, setPlayers] = useState<any>({})
  const [score, setScore] = useState<any>(null)
  const [createModalShow, setCreateModalShow] = useState<boolean>(false)
  const [estimate, setEstimate] = useState<any>({
    score: 0,
    winprob: 0
  })
  const [formData, setFormData] = useState<any>({
    handicap: 0,
    komi: 6.5,
    color: '1',
    submitMode: '1'
  })
  const listeners = useRef<any>({})

  const serverSocket = {
    send: (eventName: string, obj: any, callback?: Function) => {
      switch (eventName) {
        case 'game/move':
          handlePlayMove(obj, callback)
          break
      }
    },
    on: (eventName: string, listener: any) => {
      listeners.current[eventName] = listener
    },
  }

  useEffect(() => {
    const user = { username: "You", rank: '?', id: 1 }
    const bot = { username: "Katago", rank: '9d', id: 2 }
    const gameInfo: any = JSON.parse(localStorage.getItem('playGame') ?? '{}')
    const color = gameInfo?.color === '2' ? '2' : '1'
    setSubmitMode(gameInfo?.submitMode === '1')
    setFormData((formData: any) => ({
      ...formData,
      ...gameInfo,
      color
    }))
    setPlayers({
      black: color === '1' ? user : bot,
      white: color === '1' ? bot : user,
    })
    setConfig({
      mode: 'play',
      username: 'tester',
      player_id: 1,
      game_id: 1,
      review_id: 0,
      one_click_submit: gameInfo?.submitMode === '1',
    })
  }, [])

  useEffect(() => {
    if (config?.mode !== 'play' || !listeners?.current?.['game/1/gamedata']) {
      return
    }
    const user = { username: "You", rank: '?', id: 1 }
    const bot = { username: "Katago", rank: '9d', id: 2 }
    const gameInfo: any = JSON.parse(localStorage.getItem('playGame') ?? '{}')
    const moves = JSON.parse(localStorage.getItem('playMoves') ?? '[]')
    const color = gameInfo?.color === '2' ? '2' : '1'
    const handicap = parseInt(gameInfo?.handicap ?? 0)
    listeners?.current?.['game/1/gamedata']({
      phase: 'play',
      black_player_id: color === '1' ? user.id : bot.id,
      white_player_id: color === '1' ? bot.id : user.id,
      handicap: handicap,
      komi: parseFloat(gameInfo?.komi ?? 6.5),
      moves: moves,
    })
    if (color !== '1' && (!moves.length || moves[moves.length - 1]?.[4]?.color !== '1')) {
      handleBotMove()
    } else if (color === '1' && moves[moves.length - 1]?.[4]?.color === '1') {
      handleBotMove()
    } else if (color === '1' && handicap > 0 && !moves.length) {
      handleBotMove()
    }
  }, [config, listeners])

  const handlePlayMove = (data: any, callback?: Function) => {
    const engine = refGoban?.current?.engine()
    const move = engine.decodeMoves(data.move)[0]
    const gameInfo: any = JSON.parse(localStorage.getItem('playGame') ?? '{}')
    if (listeners?.current?.['game/1/move']) {
      const moves = JSON.parse(localStorage.getItem('playMoves') ?? '[]')
      moves.push([move.x, move.y, 0, null, { color: gameInfo?.color ?? '1' }])
      localStorage.setItem('playMoves', JSON.stringify(moves))
      listeners?.current?.['game/1/move']({
        game_id: 1,
        move: [move.x, move.y, 0, null, { sgf_downloaded_by: [] }],
        move_number: moves.length,
      })
      handleBotMove()
    }
    callback && callback()
  }

  const handleBotMove = async () => {
    const gameInfo: any = JSON.parse(localStorage.getItem('playGame') ?? '{}')
    const handicapCoordinates = [
      'Q16', 'D4', 'Q4',
      'D16', 'Q10', 'D10',
      'K16', 'K4', 'K10'
    ]
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T']
    const coordY = (y: number) => 19 - y
    const coordX = (x: number) => letters[x]
    const toStringCoordinate = (move: any) => {
      if (move[0] < 0 || move[1] < 0) {
        return 'pass'
      }
      return `${coordX(move[0])}${coordY(move[1])}`
    }
    const toObjectCoordinate = (move: string, boardSize = 19) => {
      if (move === 'pass') {
        return { x: -1, y: -1 }
      }
      return {
        x: letters.indexOf(move[0]),
        y: boardSize - parseInt(move.substr(1))
      }
    }
    const moves = JSON.parse(localStorage.getItem('playMoves') ?? '[]')
    let _moves = moves.map((move: any) => toStringCoordinate(move))
    const hMoves = []
    const handicap = gameInfo?.handicap ?? 0
    for (let h = 0; h < handicap; h++) {
      hMoves.push(handicapCoordinates[h])
      if (h < handicap - 1) {
        hMoves.push('pass')
      }
    }
    _moves = [...hMoves, ..._moves]

    const { data } = await aiPlay({
      board_size: 19,
      config: {
        komi: 6.5,
        request_id: (new Date()).getTime()
      },
      moves: _moves
    })
    const move = toObjectCoordinate(data.bot_move)
    if (listeners?.current?.['game/1/move']) {
      moves.push([move.x, move.y, 0, null, {
        color: gameInfo?.color !== '1' ? '1' : '2',
        score: data.diagnostics.score,
        winprob: data.diagnostics.winprob.toFixed(2),
      }])
      localStorage.setItem('playMoves', JSON.stringify(moves))
      listeners?.current?.['game/1/move']({
        game_id: 1,
        move: [move.x, move.y, 0, null, { sgf_downloaded_by: [] }],
        move_number: moves.length,
      })
    }
  }

  const handleStateChange = ({ moveNumber, score, moveText }: { moveNumber: number, score: any, moveText: string }) => {
    if (moveNumber !== undefined) {
      setMoveNumber(moveNumber)
    }
    if (score !== undefined) {
      setScore(score)
    }
    const moves = JSON.parse(localStorage.getItem('playMoves') ?? '[]')
    let lastMove: any = moves?.[moveNumber - 1]
    if (!lastMove?.[4]?.winprob) {
      lastMove = moves?.[moveNumber - 2]
    }
    if (lastMove?.[4]?.winprob) {
      setEstimate({
        score: parseFloat(lastMove?.[4]?.score ?? 0),
        winprob: parseFloat(lastMove?.[4]?.winprob ?? 0),
      })
    }
  }

  const handleMoveControl = useCallback((control: string) => {
    if (mode !== 'analyze') {
      setMode('analyze')
      refGoban?.current?.setMode('analyze')
    }
    refGoban?.current?.moveControl(control)
  }, [mode])

  const handleResume = () => {
    setMode('play')
    refGoban?.current?.setMode('play')
  }

  const handlePass = () => {
    refGoban?.current?.pass()
  }

  const handleSubmitMove = () => {
    refGoban?.current?.submitMove()
  }

  const handleSetAnalyzeTool = (tool: string, subtool?: string) => {
    switch (tool) {
      case 'draw':
      case 'label':
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
      case 'stone':
        if (!subtool) {
          subtool = 'alternate'
        }
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
      case 'pass':
        refGoban?.current?.pass()
        break
      case 'eraser':
        refGoban?.current?.clearAnalysisDrawing()
        break
      default:
    }
  }

  const handleCopyBranch = (callback: Function) => {
    refGoban?.current?.copyBranch(callback)
  }

  const handlePasteBranch = (move: string) => {
    refGoban?.current?.pasteBranch(move)
  }

  const handleDeleteBranch = () => {
    refGoban?.current?.deleteBranch()
  }

  const handleKeyDown = useCallback(e => {
    if (document.activeElement && ['INPUT', 'TEXTAREA'].indexOf(document.activeElement?.tagName) >= 0) {
      return
    }
    const moveKeys: any = {
      36: 'first',
      35: 'last',
      37: 'prev',
      39: 'next',
      33: 'prev10',
      34: 'next10',
      38: 'up',
      40: 'down'
    }
    if (moveKeys[e.keyCode]) {
      handleMoveControl(moveKeys[e.keyCode])
    } else {
      switch (e.keyCode) {
        case 8:
        case 46:
          handleDeleteBranch()
          break
        default:
          return
      }
    }
    e.preventDefault()
  }, [handleMoveControl])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleSetFormData = (e: any) => {
    if (e.target.name === 'handicap' && parseInt(e.target.value) > 0 && (formData.komi === 6.5 || formData.komi === '6.5')) {
      setFormData({
        ...formData,
        komi: 0.5,
        [e.target.name]: e.target.value,
      })
    } else if (e.target.name === 'handicap' && parseInt(e.target.value) === 0 && (formData.komi === 0.5 || formData.komi === '0.5')) {
      setFormData({
        ...formData,
        komi: 6.5,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }
  const handleCreateGame = (e: any) => {
    e.preventDefault()
    localStorage.setItem('playGame', JSON.stringify(formData))
    localStorage.removeItem('playMoves')
    setCreateModalShow(false)
    history.push('/blank')
    setTimeout(() => history.replace('/play'), 1)
  }
  const createModal = (
    <Modal
      show={createModalShow}
      onHide={() => setCreateModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleCreateGame}>
          <Form.Group controlId="formHandicap">
            <Form.Label>Handicap</Form.Label>
            <Form.Control as="select" name="handicap" value={formData.handicap} onChange={handleSetFormData}>
              <option value={0}>None</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formKomi">
            <Form.Label>KOMI</Form.Label>
            <Form.Control type="number" step="0.5" name="komi" value={formData.komi} onChange={handleSetFormData} />
          </Form.Group>
          <Form.Group controlId="formColor">
            <Form.Label>Màu cờ của bạn</Form.Label>
            <Form.Control as="select" name="color" value={formData.color} onChange={handleSetFormData}>
              <option value="1">Đen</option>
              <option value="2">Trắng</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formSubmitMode">
            <Form.Label>Chế độ đặt cờ</Form.Label>
            <Form.Control as="select" name="submitMode" value={formData.submitMode} onChange={handleSetFormData}>
              <option value="1">Live</option>
              <option value="0">Submit</option>
            </Form.Control>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="ml-3" variant="light" type="button" onClick={() => setCreateModalShow(false)}>Bỏ qua</Button>
            <Button className="ml-3" variant="primary" type="submit">Bắt đầu</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )

  return (
    <Container fluid className="Review-Container">
      <Row className="Review-Row">
        <Col className="Review-Col">
          <div className="d-landscape-none mb-1">
            <Players className="Players" players={players} score={score} />
          </div>
          <ReactGoban
            ref={refGoban}
            refMoveTreeContainer={refMoveTreeContainer}
            serverSocket={serverSocket}
            onStateChange={handleStateChange}
            isPlayerController={false}
            config={config}
          />
          <div className="Action-Bar">
            <ActionBar onMoveControl={handleMoveControl} moveNumber={moveNumber} />
          </div>
          <div className="d-landscape-none d-landscape-flex justify-content-center mt-3">
            {mode === 'analyze' && <AnalyzeButtonsBar
              onAnalyzeTool={handleSetAnalyzeTool}
              onPass={() => handleSetAnalyzeTool('pass')}
              onEraserDrawing={() => handleSetAnalyzeTool('eraser')}
              onCopyBranch={handleCopyBranch}
              onPasteBranch={handlePasteBranch}
              onDeleteBranch={handleDeleteBranch}
            />}
          </div>
        </Col>
        <Col className="ToolBar-Col col-landscape" sm={6} lg={4} xl={4}>
          <div className="d-portrait-none d-landscape-flex mb-3">
            <Players className="Players" players={players} score={score} />
          </div>
          <div className="Play-Controls">
            {mode === 'analyze' && <>
              <div className="d-portrait-none d-landscape-flex justify-content-center mb-3">
                <AnalyzeButtonsBar
                  onAnalyzeTool={handleSetAnalyzeTool}
                  onPass={() => handleSetAnalyzeTool('pass')}
                  onEraserDrawing={() => handleSetAnalyzeTool('eraser')}
                  onCopyBranch={handleCopyBranch}
                  onPasteBranch={handlePasteBranch}
                  onDeleteBranch={handleDeleteBranch}
                />
              </div>
              <div className="mb-3 d-flex justify-content-center">
                <Button onClick={handleResume} size="sm">Back to Game</Button>
              </div>
            </>}
            {mode === 'play' && <div className="mb-3 d-flex justify-content-center justify-content-between">
              <Button onClick={handlePass} variant="warning" size="sm">Pass</Button>
              {!submitMode && <Button onClick={handleSubmitMove} size="sm">Submit Move</Button>}
              <Button onClick={handleResume} variant="danger" size="sm">Resign</Button>
            </div>}
            <div className="mb-3 mx-1">
              <div className={"Move-Tree-Container " + (mode === 'analyze' ? 'd-block' : 'd-none')} ref={refMoveTreeContainer} />
            </div>
            <Card className="mb-3">
              <Card.Header>
                Chức năng luyện cờ cùng Katago
              </Card.Header>
              <Card.Body className="pb-0">
                <p>Ước lượng: {estimate.score > 0 ? 'đen ' : 'trắng '} thắng {estimate.score > 0 ? estimate.score : -1 * estimate.score} đất</p>
                <p>Win rate: {estimate.winprob > 0.5 ? 'đen ' : 'trắng '} ưu {(estimate.winprob > 0.5 ? estimate.winprob * 100 : (1 - estimate.winprob) * 100).toFixed(2)}%</p>
                Chức năng đang được thử nghiệm. Chúng tôi có thể đóng bất cứ lúc nào.
              </Card.Body>
              <Card.Body>
                <Button variant="success" onClick={() => setCreateModalShow(true)} block>Tạo game mới</Button>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      {createModal}
    </Container>
  )
}
