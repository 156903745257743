import * as api from "../utils/api"
import avatarEmpty from "../assets/avatars/avatar.png"
import avatar0 from "../assets/avatars/avatar0.png"
import avatar1 from "../assets/avatars/avatar1.png"
import avatar2 from "../assets/avatars/avatar2.png"
import avatar3 from "../assets/avatars/avatar3.png"
import avatar4 from "../assets/avatars/avatar4.png"
import avatar5 from "../assets/avatars/avatar5.png"
import avatar6 from "../assets/avatars/avatar6.png"
import avatar7 from "../assets/avatars/avatar7.png"
import avatar8 from "../assets/avatars/avatar8.png"

const _avatars = [
  avatar0, avatar1, avatar2,
  avatar3, avatar4, avatar5,
  avatar6, avatar7, avatar8
]
const _users: any = {}
let _userFetching = false

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ranks = [
  ...Array.from(Array(30).keys()).map((value) => (30 - value) + 'k'),
  ...Array.from(Array(9).keys()).map((value) => (1 + value) + 'd'),
]

export type UserState = {
  uid: string,
  displayName: string,
  avatar: any,
  defaultAvatar?: string,
  username?: string,
  stageName?: string,
  rankId?: number,
  rank?: string,
  totalKifu?: number,
  totalReviews?: number,
  totalLikes?: number,
  totalPuzzles?: number
}

export const getUserProfiles = async (uid: string) => {
  while (_userFetching) {
    await sleep(100)
  }
  if (_users[uid]) {
    return _users[uid]
  }
  _userFetching = true

  const data = await api.getUserProfiles(uid)
  if (data) {
    _users[uid] = {
      ...data,
      uid: uid,
      displayName: data.displayName || 'No Name',
      stageName: data?.stageName || '',
      avatar: data?.avatar,
      defaultAvatar: getDefaultAvatar(data.displayName || 'No Name'),
    }
    if (data.rankId) {
      _users[uid].rank = ranks[data.rankId]
    }
  } else {
    _users[uid] = {
      uid: uid,
      displayName: 'No Name',
      avatar: null,
      defaultAvatar: getDefaultAvatar('No Name', ''),
    }
  }

  _userFetching = false
  return _users[uid]
}

export const setUserProfiles = (uid: string, user: any) => {
  if (!uid) {
    return
  }
  _users[uid] = {
    ..._users[uid],
    ...user
  }
}

export const clearCacheUserProfiles = (uid: string) => {
  delete _users[uid]
}

export const getDefaultAvatar = (displayName: string, avatar?: string): string => {
  if (avatar) {
    return avatar
  }
  if (!displayName) {
    return avatarEmpty
  }
  const avatarNumber = displayName.length % 9
  return _avatars[avatarNumber]
}
