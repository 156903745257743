import firebase from 'firebase/app'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Button, Form, InputGroup } from 'react-bootstrap'

function ShareVariation({ onShare }) {
  const { t } = useTranslation()
  const user = firebase.auth().currentUser
  const [variation, setVariation] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    onShare(variation)
    setVariation('')
  }

  return !!user && (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control type="text" value={variation} onChange={e => setVariation(e.target.value)} placeholder={t("Variation name")} />
        <InputGroup.Append>
          <Button type="submit">{t('Share')}</Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}

ShareVariation.propTypes = {
  onShare: PropTypes.func
}

ShareVariation.defaultProps = {
  onShare: () => {}
}

export default ShareVariation
