import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import firebase from 'firebase/app'
import {useCallback, useContext, useEffect, useRef, useState} from "react"
import {
  Accordion, Alert, Button, ButtonGroup, ButtonToolbar,
  Card, Col, Container, Form, InputGroup, Row
} from "react-bootstrap"
import {Circle, Square, Triangle, XLg} from 'react-bootstrap-icons'
import {useTranslation} from "react-i18next"
import {LinkContainer} from 'react-router-bootstrap'
import {useHistory, useParams} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
import ReactGoban from "src/components/ReactGoban"
import SubmitButton from 'src/components/SubmitButton'
import {UserContext} from 'src/contexts/UserContext'
import {GobanCanvasConfig} from "src/libs/goban/src/GobanCanvas"
import {PlayerColor, PuzzlePlacementSetting} from "src/libs/goban/src/GoEngine"
import {loadSgf} from 'src/libs/jgo/sgf'
import {clearCacheUserProfiles} from 'src/models/UserModel'
import {createPuzzle, getPuzzle, updatePuzzle} from 'src/utils/api'
import "../Review/style.scss"

export default function Create(): JSX.Element {
  const { t } = useTranslation()
  const { puzzleId } = useParams<any>()
  const history = useHistory()
  const refGoban = useRef<any>(null)
  const refMoveTreeContainer = useRef<any>(null)
  const currentUser: any = useContext(UserContext)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [step, setStep] = useState<string>('1')
  const [setupStoneColor, setSetupStoneColor] = useState<string>('black')
  const [puzzleName, setPuzzleName] = useState<string>('')
  const [puzzleLevel, setPuzzleLevel] = useState<number>(3)
  const [puzzleDescription, setPuzzleDescription] = useState<string>('')
  const [playerColor, setPlayerColor] = useState<PlayerColor>('black')
  const [puzzlePlayerMoveMode, setPuzzlePlayerMoveMode] = useState<string>('free')
  const [puzzleType, setPuzzleType] = useState<string>('life_and_death')
  const [boardSize, setBoardSize] = useState<number>(19)
  const [puzzleOpponentMoveMode, setPuzzleOpponentMoveMode] = useState<string>('manual')
  const [error, setError] = useState<string>('')
  const [analyzeTool, setAnalyzeTool] = useState<string>('stone')
  const [analyzeSubtool, setAnalyzeSubtool] = useState<string>('alternate')
  const [moveText, setMoveText] = useState<string>('')

  const replacementSettingFunction = (step: string, setupStoneColor?: string): PuzzlePlacementSetting => {
    if (step === '1') {
      return {
        mode: 'setup',
        color: setupStoneColor === 'white' ? 2 : 1
      }
    } else if (step === '2') {
      return {
        mode: 'place',
        color: 0
      }
    }
    return {
      mode: 'play'
    }
  }

  const [gobanConfig, setGobanConfig] = useState<GobanCanvasConfig>({
    mode: 'puzzle',
    width: 19,
    height: 19,
    puzzle_collection: 1,
    puzzle_description: '',
    puzzle_opponent_move_mode: 'manual',
    puzzle_player_move_mode: 'free',
    puzzle_type: 'life_and_death',
    puzzle_rank: 25,
    getPuzzlePlacementSetting: () => replacementSettingFunction(step)
  })

  useEffect(() => {
    if (!puzzleId) {
      return setLoaded(true)
    }
    let isSubscribed = true
    getPuzzle(puzzleId).then((puzzle: any) => {
      if (!isSubscribed || !puzzle || !currentUser?.checkRole(['admin', 'moderator', 'owner'], puzzle?.uid, 'puzzles')) {
        return
      }
      setEditMode(true)
      setPuzzleName(puzzle.name)
      setPuzzleLevel(puzzle.level)
      setPuzzleDescription(puzzle.description)
      setPuzzleOpponentMoveMode(puzzle.opponentMoveMode)
      setPuzzlePlayerMoveMode(puzzle.playerMoveMode)
      setPuzzleType(puzzle.puzzleType)
      setBoardSize(puzzle.width)
      setPlayerColor(puzzle.initialPlayer)
      setGobanConfig(gobanConfig => ({
        ...gobanConfig,
        width: puzzle.width,
        height: puzzle.height,
        initial_state: puzzle.initialState,
        initial_player: puzzle.initialPlayer,
        move_tree: puzzle.moveTree,
      }))
      setLoaded(true)
    })
    return () => {
      isSubscribed = false
    }
  }, [currentUser, puzzleId])

  const handleSave = async () => {
    setError('')
    const user = firebase.auth().currentUser
    if (!user) {
      return setError(t('You are not login'))
    }
    const puzzle = refGoban.current.exportAsPuzzle()
    if (puzzle.initial_state.black === '' && puzzle.initial_state.white === '') {
      return setError(t('You are not setup puzzle'))
    }
    if (!puzzle.move_tree.branches || !puzzle.move_tree.branches.length) {
      return setError(t('You are not setup variations'))
    }
    const data = {
      name: puzzleName,
      width: boardSize,
      height: boardSize,
      initialPlayer: playerColor,
      initialState: puzzle.initial_state,
      moveTree: puzzle.move_tree,
      collection: 1,
      description: puzzleDescription,
      opponentMoveMode: puzzleOpponentMoveMode,
      playerMoveMode: puzzlePlayerMoveMode,
      level: puzzleLevel,
      puzzleType: puzzleType
    }
    try {
      if (!editMode) {
        await createPuzzle(data)
        clearCacheUserProfiles(user.uid)
      } else {
        await updatePuzzle(puzzleId, data)
      }
    } catch ({ response }) {
      return setError(t(response?.data?.error?.message || response?.statusText))
    }
    toast(t('Save Successfully'), { type: 'info' })
    if (!editMode) {
      history.push('/puzzles')
    } else {
      history.push('/puzzles/' + puzzleId)
    }
  }

  useEffect(() => {
    if (!loaded) {
      return
    }
    const puzzle = refGoban.current.exportAsPuzzle()
    if (step === '3') {
      return
    }
    setGobanConfig(gobanConfig => ({
      ...gobanConfig,
      width: boardSize,
      height: boardSize,
      initial_player: playerColor,
      initial_state: puzzle.initial_state,
      move_tree: puzzle.move_tree,
      getPuzzlePlacementSetting: () => replacementSettingFunction(step, setupStoneColor)
    }))
    setAnalyzeTool('stone')
    setAnalyzeSubtool('alternate')
  }, [loaded, playerColor, setupStoneColor, step, boardSize])

  const handleSelectStep = (step: string | null) => {
    if (!step) {
      return
    }
    setStep(step)
  }

  const handleClickCorrectAnswer = () => {
    refGoban?.current?.setCorrectAnswer()
  }

  const handleClickWrongAnswer = () => {
    refGoban?.current?.setIncorrectAnswer()
  }

  const handleMoveControl = (control: string) => {
    refGoban?.current?.moveControl(control)
  }

  const handleDeleteBranch = () => {
    refGoban?.current?.deleteBranch()
  }

  const handleKeyDown = useCallback(e => {
    if (document.activeElement && ['INPUT', 'TEXTAREA'].indexOf(document.activeElement?.tagName) >= 0) {
      return
    }
    const moveKeys: any = {
      key_36: 'first',
      key_35: 'last',
      key_37: 'prev',
      key_39: 'next',
      key_33: 'prev10',
      key_34: 'next10',
      key_38: 'up',
      key_40: 'down'
    }
    if (moveKeys?.['key_' + e.keyCode]) {
      handleMoveControl(moveKeys?.['key_' + e.keyCode])
    } else {
      switch (e.keyCode) {
        case 8:
        case 46:
          handleDeleteBranch()
          break
        default:
          return
      }
    }
    e.preventDefault()
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleUploadSgf = (e: any): void => {
    e.preventDefault()
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async () => {
      const content = reader?.result as string
      const jrecord: any = loadSgf(content, false)
      if (typeof jrecord === 'string') {
        toast('Error loading SGF: ' + jrecord)
        return
      }
      refGoban?.current?.clearInitialStatePlace()
      if (jrecord?.root?.jboard?.width !== boardSize) {
        setBoardSize(jrecord?.root?.jboard?.width)
        const sleep = (ms: number) => {
          return new Promise(resolve => setTimeout(resolve, ms));
        }
        await sleep(1000)
      }
      let curMove = jrecord?.root
      while (curMove) {
        if (curMove?.changes?.length) {
          for (const change of curMove?.changes) {
            try {
              refGoban?.current?.initialStatePlace(change.c.i, change.c.j, change.type)
            } catch (err) {}
          }
        }
        curMove = curMove?.children?.[0]
      }
      return
    }
    reader.readAsText(file, 'UTF-8')
  }

  const handleSetupStoneColor = (color: string) => {
    setSetupStoneColor(color)
    if (!refGoban?.current) {
      return
    }
    setAnalyzeTool('stone')
    setAnalyzeSubtool('alternate')
    refGoban?.current?.setAnalyzeTool('stone', 'alternate')
  }

  const handleAnalyzeTool = (tool: string, subtool: string) => {
    setAnalyzeTool(tool)
    setAnalyzeSubtool(subtool)
    switch (tool) {
      case 'label':
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
      case 'stone':
        if (!subtool) {
          subtool = 'alternate'
        }
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
        default:
    }
  }

  const handleChangeMoveText = (e: any) => {
    e.preventDefault()
    setMoveText(e.target.value)
    refGoban?.current?.updateMoveText(e.target.value)
  }

  const handleStateChange = ({ moveText }: { moveText: string }) => {
    setMoveText(moveText)
  }

  return (
    <Container fluid className="Review-Container">
      <Row className="Review-Row">
        <Col className="Review-Col">
          <ReactGoban
            ref={refGoban}
            refMoveTreeContainer={refMoveTreeContainer}
            isPlayerController={false}
            config={gobanConfig}
            gamedata={gobanConfig}
            onStateChange={handleStateChange}
          />
        </Col>
        <Col className="ToolBar-Col col-landscape" sm={6} lg={4} xl={4}>
          <div className="my-3">
            <Accordion defaultActiveKey="1" onSelect={handleSelectStep}>
              <Card>
                <Accordion.Toggle className="text-left rounded-0" as={Button} variant="primary" eventKey="1">
                  {t('Step 1 - Setup')}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Form.Group controlId="formName">
                      <Form.Control type="text" value={puzzleName} onChange={e => setPuzzleName(e.target.value)} placeholder={t('Puzzle Name')} />
                    </Form.Group>
                    <Form.Group controlId="formTypeAndOther">
                      <InputGroup>
                        <Form.Control as="select" value={puzzleType} onChange={e => setPuzzleType(e.target.value)}>
                          <option value="life_and_death">{t('Life and Death')}</option>
                          <option value="joseki">{t('Joseki')}</option>
                          <option value="fuseki">{t('Fuseki')}</option>
                          <option value="tesuji">{t('Tesuji')}</option>
                          <option value="best_move">{t('Best Move')}</option>
                          <option value="endgame">{t('End Game')}</option>
                          <option value="elementary">{t('Elementary')}</option>
                        </Form.Control>
                        <Form.Control as="select" value={boardSize} onChange={e => setBoardSize(parseInt(e.target.value))}>
                          <option value="9">{t('Board size')} 9x9</option>
                          <option value="13">{t('Board size')} 13x13</option>
                          <option value="19">{t('Board size')} 19x19</option>
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStones" className="Game-Analyze-Button-Bar">
                      <Form.Label column sm="5">{t('Place stones')}</Form.Label>
                      <Col sm="7">
                        <ButtonToolbar>
                          <ButtonGroup className="my-1">
                            <Button size="sm" variant={setupStoneColor === 'black' && analyzeTool ==='stone' ? 'primary' : 'light'} onClick={() => handleSetupStoneColor('black')} title={t("Place black stones")}>
                              <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/black.png" />
                            </Button>
                            <Button size="sm" variant={setupStoneColor === 'white' && analyzeTool ==='stone' ? 'primary' : 'light'} onClick={() => handleSetupStoneColor('white')} title={t("Place white stones")}>
                              <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/white.png" />
                            </Button>
                          </ButtonGroup>
                          <div className="my-1 w-100">
                            <ButtonGroup>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'letters' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'letters')} title={t("Place alphabetical labels")}><span className="p-1">A</span></Button>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'numbers' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'numbers')} title={t("Place numeric labels")}><span className="p-1">1</span></Button>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'triangle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'triangle')} title={t("Place triangle marks")}><Triangle className="md-1" /></Button>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'square' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'square')} title={t("Place square marks")}><Square className="md-1" /></Button>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'circle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'circle')} title={t("Place circle marks")}><Circle className="md-1" /></Button>
                              <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'cross' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'cross')} title={t("Place X marks")}><XLg className="md-1" /></Button>
                            </ButtonGroup>
                          </div>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formDifficulty">
                      <Form.Label column sm="5">{t('Difficulty')}</Form.Label>
                      <Col sm="7">
                      <Form.Control as="select" value={puzzleLevel} onChange={e => setPuzzleLevel(parseInt(e.target.value))}>
                        <option value={1}>{t('Very easy')}</option>
                        <option value={2}>{t('Easy')}</option>
                        <option value={3}>{t('Medium')}</option>
                        <option value={4}>{t('Hard')}</option>
                        <option value={5}>{t('Very hard')}</option>
                      </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlayerColor">
                      <Form.Label column sm="5">{t('Player color?')}</Form.Label>
                      <Col sm="7">
                      <Form.Control as="select" value={playerColor} onChange={e => setPlayerColor(e.target.value as PlayerColor)}>
                        <option value="black">{t('Black')}</option>
                        <option value="white">{t('White')}</option>
                      </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formOpponentMoveMode">
                      <Form.Label column sm="5">{t((playerColor !== 'black' ? 'Black' : 'White') + ' move mode')}</Form.Label>
                      <Col sm="7">
                      <Form.Control as="select" value={puzzleOpponentMoveMode} onChange={e => setPuzzleOpponentMoveMode(e.target.value)}>
                        <option value="manual">{t('Player controlled')}</option>
                        <option value="automatic">{t('Automatic')}</option>
                      </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlayerMoveMode">
                      <Form.Label column sm="5">{t('Player move mode')}</Form.Label>
                      <Col sm="7">
                      <Form.Control as="select" value={puzzlePlayerMoveMode} onChange={e => setPuzzlePlayerMoveMode(e.target.value)}>
                        <option value="free">{t('Free placement')}</option>
                        <option value="fixed">{t('Only allow on specified paths')}</option>
                      </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formUploadSgf">
                      <Form.Label column sm="5">{t('Upload SGF')}</Form.Label>
                      <Col sm="7">
                      <Form.Group controlId="formUploadFile" className="m-0">
                        <Form.Control type="file" accept=".sgf" onChange={handleUploadSgf} />
                      </Form.Group>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle className="text-left rounded-0" as={Button} variant="primary" eventKey="2">
                  {t('Step 2 - Answers')}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Form.Group controlId="formMoveTree">
                      <Form.Label>{t('Setup variations')}</Form.Label>
                      <div className="mt-3 mx-1">
                        <div className="Move-Tree-Container" ref={refMoveTreeContainer} />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="formToolbar" className="Game-Analyze-Button-Bar">
                      <ButtonToolbar>
                        <ButtonGroup className="mx-1">
                          <Button size="sm" variant={analyzeTool === 'stone' && analyzeSubtool !== 'black' && analyzeSubtool !== 'white' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('stone', 'alternate')} title={t("Place alternating stones")}>
                            <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/black-white.png" width="16" height="16" />
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup className="mx-1">
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'letters' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'letters')} title={t("Place alphabetical labels")}><span className="p-1">A</span></Button>
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'numbers' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'numbers')} title={t("Place numeric labels")}><span className="p-1">1</span></Button>
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'triangle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'triangle')} title={t("Place triangle marks")}><Triangle className="md-1" /></Button>
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'square' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'square')} title={t("Place square marks")}><Square className="md-1" /></Button>
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'circle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'circle')} title={t("Place circle marks")}><Circle className="md-1" /></Button>
                          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'cross' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'cross')} title={t("Place X marks")}><XLg className="md-1" /></Button>
                        </ButtonGroup>
                        <ButtonGroup className="mx-1">
                          <Button size="sm" variant="light" onClick={handleDeleteBranch} title={t("Delete branch")}>
                            <FontAwesomeIcon className="mt-1" icon="trash" />
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Form.Group>
                    <Form.Group controlId="formMoveText">
                      <Form.Control as="textarea" rows={4} placeholder={t("Move comments...")} value={moveText} onChange={handleChangeMoveText} />
                    </Form.Group>
                    <Form.Group controlId="formAnswers">
                      <Form.Label>{t('Correct or wrong?')}</Form.Label>
                      <div>
                        <Button variant="success" type="button" className="mr-3" onClick={handleClickCorrectAnswer}>{t('Correct answer')}</Button>
                        <Button variant="danger" type="button" onClick={handleClickWrongAnswer}>{t('Wrong answer')}</Button>
                      </div>
                    </Form.Group>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle className="text-left rounded-0" as={Button} variant="primary" eventKey="3">
                  {t('Step 3 - Finish')}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <Form.Group controlId="formDescription">
                      <Form.Control as="textarea" rows={4} placeholder={t("Description...")} value={puzzleDescription} onChange={e => setPuzzleDescription(e.target.value)} />
                    </Form.Group>
                    <Alert variant="danger" show={!!error} transition={false}>
                      {error}
                    </Alert>
                    <div className="text-center">
                      <LinkContainer to="/puzzles">
                        <Button variant="default" className="mr-3">{t('Cancel')}</Button>
                      </LinkContainer>
                      <SubmitButton variant="success" type="button" onClick={handleSave}>{t('Save Puzzle')}</SubmitButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  )
}
