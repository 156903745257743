import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListGroup } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { LinkContainer } from "react-router-bootstrap"
import { useLocation } from 'react-router-dom'

function SideBar({ selectIcon }) {
  const { t } = useTranslation()
  const location = useLocation()

  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <ListGroup key={location?.pathname} className="mb-3">
      <LinkContainer to="/" exact>
        <ListGroup.Item className="d-flex justify-content-between align-items-center" action onClick={handleClick}>
          <span>
            <FontAwesomeIcon icon="user" className="mr-3" />
            {t('New Activities')}
          </span>
          {location?.pathname === '/' && <FontAwesomeIcon icon={selectIcon} />}
        </ListGroup.Item>
      </LinkContainer>
      <LinkContainer to="/feed/reviews" exact>
        <ListGroup.Item className="d-flex justify-content-between align-items-center" action onClick={handleClick}>
          <span>
            <FontAwesomeIcon icon="calendar" className="mr-3" />
            {t('New Reviews')}
          </span>
          {location?.pathname === '/feed/reviews' && <FontAwesomeIcon icon={selectIcon} />}
        </ListGroup.Item>
      </LinkContainer>
      <LinkContainer to="/feed/kifu" exact>
        <ListGroup.Item className="d-flex justify-content-between align-items-center" action onClick={handleClick}>
          <span>
            <FontAwesomeIcon icon="clock" className="mr-3" />
            {t('New Kifu')}
          </span>
          {location?.pathname === '/feed/kifu' && <FontAwesomeIcon icon={selectIcon} />}
        </ListGroup.Item>
      </LinkContainer>
      <LinkContainer to="/feed/puzzles" exact>
        <ListGroup.Item className="d-flex justify-content-between align-items-center" action onClick={handleClick}>
          <span>
            <FontAwesomeIcon icon="puzzle-piece" className="mr-3" />
            {t('New Puzzles')}
          </span>
          {location?.pathname === '/feed/puzzles' && <FontAwesomeIcon icon={selectIcon} />}
        </ListGroup.Item>
      </LinkContainer>
    </ListGroup>
  )
}

SideBar.propTypes = {
  selectIcon: PropTypes.string,
  onSelect: PropTypes.func
}

SideBar.defaultProps = {
  selectIcon: 'angle-right',
  onSelect: () => {}
}

export default SideBar
