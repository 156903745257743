import { useState } from 'react'
import { useEffect } from 'react'
import { Card, ListGroup } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import { useTranslation } from "react-i18next"
import { listUserProfiles } from 'src/utils/api'
import { User } from '../Model'
import { Transformation } from '../CloudinaryImage'
import AvatarImage from './AvatarImage'

export default function TopReviewer(): JSX.Element {
  const { t } = useTranslation()
  const [users, setUsers] = useState<Array<any>>([])

  useEffect(() => {
    let isSubscribed = true
    listUserProfiles({
      filter: {
        order: ['totalLikes desc', 'totalPuzzles desc'],
        limit: 5
      }
    }).then(data => {
      if (!isSubscribed) {
        return
      }
      setUsers(data)
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <Card className="Top-Reviewers mb-3">
      <ListGroup variant="flush">
        {!!users.length && users.map(user => (
          <User key={user.uid} data={user}>
            {(user) => (
              <ListGroup.Item>
                <div className="d-flex flex-row">
                  <div className="Avatar mr-3">
                    <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                      <AvatarImage className="w-100 rounded-circle" user={user}>
                        <Transformation width="80" height="80" gravity="auto" crop="fill" />
                      </AvatarImage>
                    </LinkContainer>
                  </div>
                  <div className="Username d-flex align-items-center">
                    <div>
                      <div>
                        <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                          <a href={"/users/" + (user?.username || user.uid)} className="UserLink">{user.displayName}</a>
                        </LinkContainer>
                      </div>
                      <div className="text-muted">{user.totalLikes} {t(user.totalLikes === 1 ? 'Like' : 'Likes')}</div>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            )}
          </User>
        ))}
      </ListGroup>
    </Card>
  )
}
