import axios from "axios"
import firebase from 'firebase/app'
import {apiUrl, botApiUrl} from "src/configs"

export const aiPlay = async (payload: any) => {
  return await axios({
    method: 'post',
    url: botApiUrl,
    data: {
      uri: '/select-move-guest/katago_gtp_bot',
      payload
    }
  })
}

export const registerUser = async (data: any) => {
  return axios({
    method: 'post',
    url: apiUrl + '/register',
    data,
  })
}

export const updateUserProfiles = async (data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'patch',
    url: apiUrl + '/user-profiles',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const getUserProfiles = async (uid: string) => {
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/user-profiles/' + uid
  })
  return data
}

export const listUserProfiles = async (params?: any) => {
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/user-profiles',
    params
  })
  return data
}

export const getMeProfiles = async () => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/me-profiles',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
  })
  return data
}

export const countPuzzles = async (params?: any) => {
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/puzzles/count',
    params
  })
  return data
}

export const listPuzzles = async (params?: any) => {
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/puzzles',
    params
  })
  return data
}

export const createPuzzle = async (data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/puzzles',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const updatePuzzle = async (id: string, data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'patch',
    url: apiUrl + '/puzzles/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const puzzleAddCorrectAnswerUsers = async (id: string) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/puzzles/' + id + '/correctAnswerUsers',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  })
}

export const getPuzzle = async (id: string, params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/puzzles/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params,
  })
  return data
}

export const deletePuzzle = async (id: string) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'delete',
    url: apiUrl + '/puzzles/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
  })
}

export const updatePuzzleOffical = async (id: string, data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'patch',
    url: apiUrl + '/puzzles/' + id + '/official',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const listKifu = async (params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/kifu',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params
  })
  return data
}

export const countKifu = async (params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/kifu/count',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params,
  })
  return data
}

export const createKifu = async (data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/kifu',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const updateKifu = async (id: string, data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'patch',
    url: apiUrl + '/kifu/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const getKifu = async (id: string, params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/kifu/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params,
  })
  return data
}

export const deleteKifu = async (id: string) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'delete',
    url: apiUrl + '/kifu/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
  })
}

export const createReview = async (kifuId: string) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/kifu/' + kifuId + '/reviews',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  })
}

export const getReview = async (id: string, params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/reviews/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params,
  })
  return data
}

export const createReviewLike = async (reviewId: string) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/reviews/' + reviewId + '/likes',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  })
}

export const createReviewMessage = async (reviewId: string, data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + '/reviews/' + reviewId + '/review-messages',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const listReviewLikes = async (params?: any) => {
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/review-likes',
    params
  })
  return data
}

export const listFeed = async (params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/feed',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params
  })
  return data
}

export const getFeed = async (id: string, params?: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const { data } = await axios({
    method: 'get',
    url: apiUrl + '/feed/' + id,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    params,
  })
  return data
}

export const createMessage = async (objectId: string, type: 'kifu' | 'reviews', data: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  return axios({
    method: 'post',
    url: apiUrl + `/${type}/${objectId}/messages`,
    headers: {
      'Authorization': 'Bearer ' + accessToken
    },
    data
  })
}

export const upload = async (file: any) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken()
  const formData = new FormData()
  formData.append('image', file)
  return axios({
    method: 'post',
    url: apiUrl + '/upload',
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'multipart/form-data'
    },
    data: formData,
  })
}
