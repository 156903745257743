import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useContext} from 'react'
import {Button, Card, ListGroup} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap"
import {UserContext} from 'src/contexts/UserContext'
import {UserState} from "src/models/UserModel"
import {Transformation} from "../CloudinaryImage"
import {User} from "../Model"
import AvatarImage from "./AvatarImage"

interface UserInfoProps {
  uid: string | null,
  hideProfileLink?: boolean
}

function UserInfo({ uid, hideProfileLink }: UserInfoProps): JSX.Element {
  const { t } = useTranslation()
  const currentUser: any = useContext(UserContext)
  let userId = uid

  if (!userId) {
    userId = currentUser?.uid
  }
  if (!userId) {
    return <></>
  }

  return <User uid={userId}>
    {(user: UserState) => (
      <Card className="User-Info-Container mb-3 overflow-hidden">
        <ListGroup variant="flush">
          <ListGroup.Item className="User-Info text-center border-0">
            <div className="Background bg-dark"></div>
            <div className="Avatar-Container">
              <AvatarImage className="w-100 img-thumbnail rounded-circle" user={user}>
                <Transformation width="400" height="400" gravity="auto" crop="fill" />
              </AvatarImage>
              <AvatarImage className="Avatar img-thumbnail rounded-circle" user={user}>
                <Transformation width="400" height="400" gravity="auto" crop="fill" />
              </AvatarImage>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="text-center pt-0">
            <h3>{user.displayName}</h3>
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            <h5 className="m-0">{t('Kifu')}</h5>
            <div>{user?.totalKifu || 0}</div>
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            <h5 className="m-0">{t('Reviews')}</h5>
            <div>{user?.totalReviews || 0}</div>
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            <h5 className="m-0">{t('Puzzles')}</h5>
            <div>{user?.totalPuzzles || 0}</div>
          </ListGroup.Item>
          {!hideProfileLink && <ListGroup.Item className="text-center">
            <LinkContainer to={"/users/" + (user?.username || user.uid)}>
              <Button className="d-flex justify-content-between align-items-center" variant="success" block>
                <FontAwesomeIcon icon="address-card" className="mr-3" />
                {t('View Profile')}
                <span className="mx-3">&nbsp;</span>
              </Button>
            </LinkContainer>
          </ListGroup.Item>}
        </ListGroup>
      </Card>
    )}
  </User>
}

export default UserInfo
