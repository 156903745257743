import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import {FormEvent, useContext, useState} from "react"
import {Alert, Button, Card, Col, Container, Form, Modal, Row, Tab, Tabs} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {LinkContainer} from 'react-router-bootstrap'
import {toast, ToastContainer} from 'react-toastify'
import {UserContext} from 'src/contexts/UserContext'
import {loadSgf, sgfRecordToGamedata} from 'src/libs/jgo/sgf'
import {clearCacheUserProfiles, UserState} from 'src/models/UserModel'
import {countKifu, createKifu} from 'src/utils/api'
import {getGameDetails} from 'src/utils/ogs'
import {Transformation} from '../CloudinaryImage'
import {If} from '../Div'
import {User} from '../Model'
import SubmitButton from '../SubmitButton'
import AvatarImage from './AvatarImage'

interface CreatePostProps {
  onDone?: Function
}

export default function CreatePost({ onDone }: CreatePostProps): JSX.Element {
  const { t } = useTranslation()
  const user = firebase.auth().currentUser
  const currentUser: any = useContext(UserContext)
  const [category, setCategory] = useState('')
  const [game, setGame] = useState('')
  const [note, setNote] = useState('')
  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState('')
  const [errorUpload, setErrorUpload] = useState('')
  const [createModalShow, setCreateModalShow] = useState<boolean>(false)
  const [feedbackModalShow, setFeedbackModalShow] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)

  if (!user) {
    return <></>
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setError('')
    const user = firebase.auth().currentUser
    if (!user) {
      return setError(t('You are not login'))
    }
    try {
      const gameId = game.split('/').pop()
      const { count: exists } = await countKifu({
        where: {
          'sourceData.id': parseInt(gameId ?? '0')
        }
      })
      if (exists) {
        throw new Error(t('Kifu exists'))
      }
      const details = await getGameDetails(gameId)
      if (!details.gamedata) {
        throw new Error(t('Game data not found'))
      }
      const formData: any = {
        note,
        width: parseInt(details?.width ?? 19),
        height: parseInt(details?.height ?? 19),
        initialPlayer: details?.gamedata?.initial_player ?? 'black',
        komi: parseFloat(details?.komi ?? 6.5),
        handicap: parseInt(details?.handicap ?? 0),
        freeHandicapPlacement: details?.gamedata?.free_handicap_placement ?? false,
        rules: details?.rules ?? 'japanese',
        moves: details?.gamedata?.moves ?? [],
        source: 'ogs',
        sourceData: details,
        phase: details?.gamedata?.phase ?? 'finished',
      }
      if (category) {
        formData.categoryId = category
      }
      const { data: kifu } = await createKifu(formData)
      setGame('')
      setNote('')
      clearCacheUserProfiles(user.uid)
      onDone && onDone(kifu)
    } catch (err:any) {
      setError(t('Game link is invalid: ') + (err?.response?.data?.detail || err.message))
      return
    }
  }

  const handleUpload = (e: any): void => {
    e.preventDefault()
    setErrorUpload('')
    if (!selectedFile) {
      return
    }
    const reader = new FileReader()
    reader.onload = async () => {
      const content = reader?.result as string
      const jrecord = loadSgf(content, false)
      if (typeof jrecord === 'string') {
        setErrorUpload('Error loading SGF: ' + jrecord)
        return
      }
      const gamedata: any = sgfRecordToGamedata(jrecord)
      const formData: any = {
        note,
        width: parseInt(gamedata?.width ?? 19),
        height: parseInt(gamedata?.height ?? 19),
        komi: parseFloat(gamedata?.komi ?? 6.5),
        handicap: parseInt(gamedata?.handicap ?? 0),
        freeHandicapPlacement: gamedata?.free_handicap_placement ?? false,
        rules: gamedata?.rules ?? 'japanese',
        moves: gamedata?.moves ?? [],
        source: 'sgf',
        sourceData: {
          ...gamedata,
          sgf: content,
          gamedata,
        },
        phase: (gamedata?.black_lost !== gamedata?.white_lost) ? 'finished' : 'play',
      }
      if (category) {
        formData.categoryId = category
      }
      const { data: kifu } = await createKifu(formData)
      setGame('')
      setNote('')
      clearCacheUserProfiles(user.uid)
      onDone && onDone(kifu)
    }
    reader.readAsText(selectedFile, 'UTF-8')
  }

  const createModal = (
    <Modal
      show={createModalShow}
      onHide={() => setCreateModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Tabs defaultActiveKey="upload" id="create-tab" className="mb-3">
          <Tab eventKey="upload" title={t("Upload")}>
            <Form>
              <Form.Group controlId="formUploadFile">
                <Form.Control type="file" accept=".sgf" onChange={(e: any) => setSelectedFile(e.target.files[0])} />
              </Form.Group>
              <If show={currentUser?.checkRole(['admin', 'moderator', 'supporter'])}>
                <Form.Group controlId="formCategory">
                  <Form.Control as="select" value={category} onChange={e => setCategory(e.target.value)}>
                    <option value="">{t('Category')}</option>
                    <option value={process.env.REACT_APP_CATEGORY_CUP_LS_2021}>Cúp LS 2021</option>
                  </Form.Control>
                </Form.Group>
              </If>
              <Form.Group controlId="formNoteUpload">
                <Form.Control type="text" placeholder={t("Note")} value={note} onChange={e => setNote(e.target.value)} />
                <Form.Text className="text-muted">{t('Please note your color and rank')}</Form.Text>
              </Form.Group>
              <Alert variant="danger" show={!!errorUpload}>
                {errorUpload}
              </Alert>
              <div className="d-flex justify-content-end">
                <SubmitButton className="ml-3" variant="primary" type="submit" onClick={handleUpload}>
                  {t('Upload kifu')}
                </SubmitButton>
              </div>
            </Form>
          </Tab>
          <Tab eventKey="ogs" title={t('Create Kifu from OGS')}>
            <Form>
              <Form.Group controlId="formGameLink">
                <Form.Control type="text" placeholder={t("Ex: https://online-go.com/game/35232512", { keySeparator: ":" })} value={game} onChange={e => setGame(e.target.value)} required />
              </Form.Group>
              <If show={currentUser?.checkRole(['admin', 'moderator', 'supporter'])}>
                <Form.Group controlId="formCategory">
                  <Form.Control as="select" value={category} onChange={e => setCategory(e.target.value)}>
                    <option value="">{t('Category')}</option>
                    <option value="61bdfffeedaf6824a706a69a">Cúp LS 2021</option>
                  </Form.Control>
                </Form.Group>
              </If>
              <Form.Group controlId="formNote">
                <Form.Control type="text" placeholder={t("Note")} value={note} onChange={e => setNote(e.target.value)} />
                <Form.Text className="text-muted">{t('Please note your color and rank')}</Form.Text>
              </Form.Group>
              <Alert variant="danger" show={!!error}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <SubmitButton className="ml-3" variant="primary" type="submit" onClick={handleSubmit}>
                  {t('Create Kifu')}
                </SubmitButton>
              </div>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  )

  const handleFeedback = async (e: any): Promise<void> => {
    e.preventDefault()
    if (!feedback) {
      return
    }
    await firebase.firestore().collection('feedback').doc('test').get()
    await firebase.firestore().collection('feedback').add({
      uid: user.uid,
      displayName: user.displayName,
      feedback,
      createdAt: (new Date()).toISOString()
    })
    setFeedbackModalShow(false)
    toast(t('Thank you for your feedback'), { type: 'success', autoClose: 4000 })
    setFeedback('')
  }

  const feedbackModal = (
    <Modal
      show={feedbackModalShow}
      onHide={() => setFeedbackModalShow(false)}
      aria-labelledby="contained-feedback-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{t('Send feedback')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control as="textarea" placeholder={t("Content...")} rows={4} value={feedback} onChange={e => setFeedback(e.target.value)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => setFeedbackModalShow(false)}>{t('Cancel')}</Button>
        <SubmitButton onClick={handleFeedback}>{t('Submit')}</SubmitButton>
      </Modal.Footer>
    </Modal>
  )

  return (<>
    <Card className="Post-Card mb-3">
      <Card.Body className="Post-Header d-flex flex-row">
        <User uid={user.uid}>
          {(user: UserState) => (
            <div className="Avatar mr-3">
              <AvatarImage className="w-100 rounded-circle" user={user}>
                <Transformation width="80" height="80" gravity="auto" crop="fill" />
              </AvatarImage>
            </div>
          )}
        </User>
        <div className="Username d-flex align-items-center w-100">
          <Button className="Create-Upload-Kifu border text-left" variant="light" onClick={() => setCreateModalShow(true)} block>
            {t('Create or Upload Kifu')}
          </Button>
        </div>
      </Card.Body>
      <Container className="border-top py-2">
        <Row>
          <Col>
            <LinkContainer to={"/puzzles/create"}>
              <Button variant="default" block>
                <FontAwesomeIcon icon="puzzle-piece" /> {t('Create Puzzle')}
              </Button>
            </LinkContainer>
          </Col>
          <Col>
            <Button variant="default" onClick={() => setFeedbackModalShow(true)} block>
              <FontAwesomeIcon icon="seedling" /> {t('Send feedback')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
    <ToastContainer />
    {createModal}
    {feedbackModal}
  </>)
}
