import ReactResizeDetector from 'react-resize-detector'
import { forwardRef, useEffect, useImperativeHandle, useRef, useCallback, useState } from 'react'
import { GobanCanvas, GobanCanvasConfig } from 'src/libs/goban/src/GobanCanvas'
import { MoveTree } from 'src/libs/goban/src/MoveTree'
import './styleminigoban.scss'

interface MiniGobanProps {
  refSelf?: any,
  id?: string;
  width?: number;
  height?: number;
  black?: any;
  white?: any;
  onUpdate?: () => void;
  config?: any,
  gamedata?: any;
  noLink?: boolean;
  noText?: boolean;
}

function MiniGoban(props:MiniGobanProps):JSX.Element {
  const { refSelf, width, height, gamedata, config: moreConfig } = props
  const refGoban = useRef<HTMLDivElement>(null)
  const refGobanContainer = useRef<HTMLDivElement>(null)
  const [loaded, setLoaded] = useState(false)
  let goban = useRef<GobanCanvas>()

  const recenterGoban = () => {
    if (!goban?.current || !refGoban?.current || !refGobanContainer?.current) {
      return
    }
    const m = goban.current.computeMetrics()
    refGoban.current.style.top = (Math.ceil(refGobanContainer.current.offsetHeight - m.height) / 2) + 'px'
    refGoban.current.style.left = (Math.ceil(refGobanContainer.current.offsetWidth - m.width) / 2) + 'px'
  }

  let timeoutResizeDebounce:any
  const handleResize = useCallback((noDebounce: boolean = false, skipStateUpdate: boolean = false) => {
    if (!goban || !goban.current || !refGobanContainer.current) {
      return;
    }
    if (timeoutResizeDebounce) {
      clearTimeout(timeoutResizeDebounce)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutResizeDebounce = null
    }
    if (!noDebounce) {
      timeoutResizeDebounce = setTimeout(() => handleResize(true), 50)
      recenterGoban()
      return
    }
    goban.current.setSquareSizeBasedOnDisplayWidth(
      Math.min(refGobanContainer.current.offsetWidth, refGobanContainer.current.offsetHeight)
    )
    recenterGoban()
  }, [goban, refGobanContainer])

  useEffect(() => {
    if (!refGoban.current) {
      return
    }
    const config: GobanCanvasConfig = {
      board_div: refGoban.current,
      display_width: refGobanContainer?.current?.offsetWidth,
      square_size: 'auto',
      width: width,
      height: height,
      ...moreConfig
    }
    goban?.current?.destroy()
    if (gamedata) {
      if (gamedata.removed) {
        delete gamedata.removed
      }
      goban.current = new GobanCanvas(config, gamedata)
    } else {
      goban.current = new GobanCanvas(config)
    }
    setLoaded(true)
    handleResize()

    goban.current.on('update', () => {
      handleResize()
    })
    return () => {
      goban?.current?.destroy()
    }
  }, [gamedata, handleResize, height, moreConfig, width])

  useImperativeHandle(refSelf, () => ({
    syncTrunkMoves(moves:Array<any>) {
      if (!goban.current) {
        return
      }
      const isLastOfficialMove:boolean = goban?.current?.engine?.isLastOfficialMove()
      const curMove:MoveTree = goban?.current?.engine?.cur_move
      goban?.current?.jumpToLastOfficialMove()
      const lastOfficialMove = goban.current.engine.last_official_move
      for (let i = lastOfficialMove.move_number; i < moves.length; i++) {
        const newMove = moves[i]
        try {
          goban?.current?.engine?.place(newMove[0], newMove[1], false, false, false, true, true)
        } catch (err) {
          console.warn(err)
        }
      }
      goban?.current?.engine?.setLastOfficialMove()
      if (isLastOfficialMove) {
        goban?.current?.jumpToLastOfficialMove()
      } else {
        goban?.current?.engine?.jumpTo(curMove)
      }
      goban?.current?.syncReviewMove()
    }
  }))

  return (
    <div className="MiniGoban-Container" ref={refGobanContainer}>
      {loaded && <ReactResizeDetector handleWidth handleHeight onResize={() => handleResize()} targetDomEl={refGobanContainer.current || undefined}/>}
      <div className="Goban" ref={refGoban} />
    </div>
  )
}

export default forwardRef((props:MiniGobanProps, ref) => <MiniGoban {...props} refSelf={ref} />)
