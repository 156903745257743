import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from '@react-firebase/auth'
import firebase from 'firebase/app'
import {
  Container, Nav, Navbar, NavDropdown
} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { LinkContainer } from 'react-router-bootstrap'
import { useHistory } from 'react-router-dom'
import { Transformation } from "../CloudinaryImage"
import AvatarImage from "../Feed/AvatarImage"
import { User } from '../Model'
import ActiveGames from './ActiveGames'

function Header() {
  const { t } = useTranslation()
  const history = useHistory()
  const handleLogout = e => {
    e.preventDefault()
    firebase.auth().signOut().then(() => {
      history.push('/')
    })
  }
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <LinkContainer to="/" exact>
          <Navbar.Brand>Kifu</Navbar.Brand>
        </LinkContainer>
        <Nav className="d-lg-none mr-auto">
          <LinkContainer to="/puzzles" exact>
            <Nav.Link active={false}><FontAwesomeIcon icon="puzzle-piece" /></Nav.Link>
          </LinkContainer>
        </Nav>
        <ActiveGames className="d-lg-none ml-auto"/>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <LinkContainer to="/kifu" exact>
              <Nav.Link active={false}>{t('Kifu')}</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/categories/cup-ls-2021" exact>
              <Nav.Link active={false}>Giải vô địch quốc gia - Tranh cúp LS 2021</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/puzzles" exact>
              <Nav.Link active={false}>{t('Puzzles')}</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            <IfFirebaseUnAuthed>
              {() => (
                <LinkContainer to="/login" exact>
                  <Nav.Link active={false}>{t('Login')}</Nav.Link>
                </LinkContainer>
              )}
            </IfFirebaseUnAuthed>
            <IfFirebaseAuthed>
              {({user}) => (
                <User uid={user.uid}>
                  {(user) => (<>
                    <ActiveGames className="d-none d-lg-block" />
                    <NavDropdown alignRight className="Nav-Link" title={(<>
                      <AvatarImage className="rounded-circle mr-2" user={user}>
                        <Transformation width="80" height="80" gravity="auto" crop="fill" />
                      </AvatarImage>
                      <span>{user.displayName || 'User'}</span>
                    </>)} id="user-nav-dropdown">
                      <LinkContainer to={"/users/" + (user?.username || user.uid)} exact>
                        <NavDropdown.Item active={false}>{t('My Profile')}</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/settings" exact>
                        <NavDropdown.Item active={false}>{t('Settings')}</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={handleLogout}>{t('Logout')}</NavDropdown.Item>
                    </NavDropdown>
                  </>)}
                </User>
              )}
            </IfFirebaseAuthed>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
