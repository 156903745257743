import firebase from "firebase/app"
import { ReactNode, useState, useEffect } from "react"
import { createReviewLike, listReviewLikes } from "src/utils/api"

interface LikeProps {
  objectId: string,
  children?: (liked: boolean, handleLike: Function) => ReactNode
}

export default function Like({ objectId, children }: LikeProps): JSX.Element {
  const user = firebase.auth().currentUser
  const [loaded, setLoaded] = useState<boolean>(false)
  const [liked, setLiked] = useState<boolean>(false)

  const handleLike = (e: any, callback?: Function) => {
    e.preventDefault()
    if (!loaded || !user || !objectId) {
      return
    }
    createReviewLike(objectId).then(({ data }) => {
      setLiked(!!data)
      callback && callback(!!data)
    })
  }

  useEffect(() => {
    if (!user || !objectId) {
      return
    }
    let isSubscribed = true
    listReviewLikes({
      filter: {
        where: {
          reviewId: objectId,
          uid: user.uid
        }
      }
    }).then(likes => {
      if (!isSubscribed) {
        return
      }
      setLoaded(true)
      setLiked(!!likes.length)
    })
    return () => {
      isSubscribed = false
    }
  }, [objectId, user])

  return (
    <>
      {user && children && children(liked, handleLike)}
    </>
  )
}
