import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import { useRef, useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { getGameDetails } from 'src/utils/ogs'
import { getUserProfiles } from 'src/models/UserModel'
import { useTranslation } from "react-i18next"
import { createMessage, createReview, getKifu, updateKifu } from 'src/utils/api'
import ReactGoban from '../../components/ReactGoban'
import ActionBar from '../../components/ReactGoban/ActionBar'
import Players from '../../components/ReactGoban/Players'
import AnalyzeButtonsBar from '../../components/ReactGoban/AnalyzeButtonsBar'
import ShareVariation from 'src/components/ReactGoban/ShareVariation'
import Chat from 'src/components/ReactGoban/Chat'
import './style.scss'

function Kifu() {
  const { t } = useTranslation()
  const { kifuId } = useParams()
  const refGoban = useRef(null)
  const refMoveTreeContainer = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [kifu, setKifu] = useState(null)
  const [moveNumber, setMoveNumber] = useState(0)
  const [score, setScore] = useState(null)
  const [reviews, setReviews] = useState([])
  const [chatMessages, setChatMessages] = useState([])
  const [isOwner, setIsOwner] = useState(false)
  const listeners = useRef({})
  const history = useHistory()

  const serverSocket = {
    send: (eventName, obj) => {
      switch (eventName) {
        case 'review/chat':
          return handleReviewChat(obj)
        default:
      }
    },
    on: (eventName, listener) => {
      listeners.current[eventName] = listener
    },
  }

  useEffect(() => {
    const user = firebase.auth().currentUser
    const params = { filter: {
      include: [
        {
          relation: 'reviews',
          scope: {
            order: 'totalLikes DESC',
            include: [
              { relation: 'user' }
            ] 
          }
        }
      ]
    } }
    let chatMessagesRef
    let isSubscribed = true
    getKifu(kifuId, params).then(async (kifu) => {
      if (!isSubscribed || !kifu) {
        return
      }
      setLoaded(true)
      setKifu(kifu)
      setReviews(kifu?.reviews ?? [])
      setIsOwner(kifu?.uid === user?.uid)
      chatMessagesRef = firebase.database().ref('kifu').child(kifuId).child('chat')
      chatMessagesRef.on('child_added', (snapshot, prevChildKey) => {
        const message = snapshot.val()
        if (!message) {
          return
        }
        setChatMessages(chatMessages => [
          ...chatMessages,
          message
        ])
      })
    }).catch(err => {
      if (err?.response?.status === 404) {
        return history.replace('/404')
      }
      return history.replace('/error')
    })

    return () => {
      isSubscribed = false
      if (chatMessagesRef) {
        chatMessagesRef.off('child_added')
      }
    }
  }, [history, kifuId])

  useEffect(() => {
    if (!kifu) {
      return
    }
    let isSubscribed = true
    let reviewMessagesRef
    const loadReviewState = async () => {
      if (!isSubscribed) {
        return
      }
      refGoban?.current?.doneLoadingReview(false)
      refGoban?.current?.setMode('play')
      refGoban?.current?.showMessage('Loading...', -1)
      let messages = [{ gamedata: kifu?.sourceData?.gamedata || {} }]
      refGoban?.current?.setMode('analyze')

      if (listeners?.current?.['review/1/full_state']) {
        listeners?.current?.['review/1/full_state'](messages)
      }
      refGoban?.current?.emit('update')
    }
    loadReviewState()

    return () => {
      isSubscribed = false
      if (reviewMessagesRef) {
        reviewMessagesRef.off('child_added')
      }
    }
  }, [kifu, listeners])

  // Owner - Sync moves from OGS game
  useEffect(() => {
    if (!isOwner || !kifu || kifu?.phase === 'finished' || !kifu?.sourceData?.id) {
      return
    }
    let isSubscribed = true
    const interval = setInterval(async () => {
      const details = await getGameDetails(kifu?.sourceData?.id)
      if (!details.gamedata || !isSubscribed) {
        return clearInterval(interval)
      }
      const newKifu = {
        width: parseInt(details?.width ?? 19),
        height: parseInt(details?.height ?? 19),
        initialPlayer: details?.gamedata?.initial_player ?? 'black',
        komi: parseFloat(details?.komi ?? 6.5),
        handicap: parseInt(details?.handicap ?? 0),
        freeHandicapPlacement: details?.gamedata?.free_handicap_placement ?? false,
        rules: details?.rules ?? 'japanese',
        moves: details?.gamedata?.moves ?? [],
        sourceData: details,
        phase: details?.gamedata?.phase ?? 'finished',
      }
      await updateKifu(kifuId, newKifu)
      if (!isSubscribed) {
        return clearInterval(interval)
      }
      refGoban?.current?.syncTrunkMoves(details?.gamedata?.moves)
      if (details?.gamedata?.phase === 'finished') {
        setKifu({
          ...kifu,
          ...newKifu
        })
        clearInterval(interval)
      }
    }, 10000)
    return () => {
      isSubscribed = false
      clearInterval(interval)
    }
  }, [isOwner, kifu, kifuId])

  // Viewer - Sync move from firebase
  useEffect(() => {
    if (isOwner || !kifu || kifu?.phase === 'finished' || !kifu?.sourceData?.id) {
      return
    }
    let isSubscribed = true
    const interval = setInterval(async () => {
      const newKifu = await getKifu(kifuId)
      if (!newKifu || !isSubscribed) {
        return clearInterval(interval)
      }
      refGoban?.current?.syncTrunkMoves(newKifu?.moves)
      if (newKifu?.phase === 'finished') {
        setKifu(newKifu)
        clearInterval(interval)
      }
    }, 3000)
    return () => {
      isSubscribed = false
      clearInterval(interval)
    }
  }, [isOwner, kifu, kifuId])

  const handleStateChange = ({ moveNumber, score, moveText }) => {
    if (moveNumber !== undefined) {
      setMoveNumber(moveNumber)
    }
    if (score !== undefined) {
      setScore(score)
    }
  }

  const handleCreateReview = async () => {
    const user = firebase.auth().currentUser
    if (!user) {
      return
    }
    const { data: review } = await createReview(kifuId)
    const profile = await getUserProfiles(user.uid)
    refGoban?.current?.createReview(review.id, profile?.stageName || profile?.displayName)
    history.push('/reviews/' + review.id)
  }

  const handleReviewChat = useCallback(async (message) => {
    const user = firebase.auth().currentUser
    if (!user) {
      return
    }
    let displayName = user.displayName
    if (message.isPlayerController) {
      const profile = await getUserProfiles(user.uid)
      if (profile?.stageName) {
        displayName = profile?.stageName
      }
    }
    delete message.review_id
    delete message.isPlayerController
    await firebase.database().ref('kifu').child(kifuId).child('chat').push({
      ...message,
      user: {
        uid: user.uid,
        displayName
      },
      createdAt: firebase.firestore.Timestamp.now().seconds * 1000
    })
    createMessage(kifuId, 'kifu', { content: message })
  }, [kifuId])

  const handleShareVariation = name => {
    refGoban?.current?.shareAnalysis(name || 'noname')
  }

  const handleHoverVariation = variation => {
    refGoban?.current?.hoverVariation(variation)
  }

  const handleLeaveVariation = () => {
    refGoban?.current?.leaveVariation()
  }

  const handleClickVariation = variation => {
    refGoban?.current?.leaveVariation()
    refGoban?.current?.clickVariation()
    refGoban?.current?.jumpToMove(variation)
  }

  const handleHoverPositionText = position => {
    refGoban?.current?.showMarkPosition(position)
  }

  const handleLeavePositionText = position => {
    refGoban?.current?.hideMarkPosition(position)
  }

  const handleMoveJump = message => {
    refGoban?.current?.jumpToMove(message)
  }

  const handleReviewSelect = e => {
    e.preventDefault()
    if (e.target.value) {
      history.push('/reviews/' + e.target.value)
    }
  }

  const handleEnterChat = message => {
    refGoban?.current?.sendChat(message, 'review')
  }

  const handleMoveControl = control => {
    refGoban?.current?.moveControl(control)
  }

  const handleSetAnalyzeTool = (tool, subtool) => {
    switch (tool) {
      case 'draw':
      case 'label':
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
      case 'stone':
        if (!subtool) {
          subtool = 'alternate'
        }
        refGoban?.current?.setAnalyzeTool(tool, subtool)
        break
      case 'pass':
        refGoban?.current?.pass()
        break
      case 'eraser':
        refGoban?.current?.clearAnalysisDrawing()
        break
      default:
    }
  }

  const handleCopyBranch = (callback) => {
    refGoban?.current?.copyBranch(callback)
  }

  const handlePasteBranch = (move) => {
    refGoban?.current?.pasteBranch(move)
  }

  const handleDeleteBranch = () => {
    refGoban?.current?.deleteBranch()
  }

  const handleKeyDown = useCallback(e => {
    if (document.activeElement && ['INPUT', 'TEXTAREA'].indexOf(document.activeElement?.tagName) >= 0) {
      return
    }
    const moveKeys = {
      36: 'first',
      35: 'last',
      37: 'prev',
      39: 'next',
      33: 'prev10',
      34: 'next10',
      38: 'up',
      40: 'down'
    }
    if (moveKeys[e.keyCode]) {
      handleMoveControl(moveKeys[e.keyCode])
    } else {
      switch (e.keyCode) {
        case 8:
        case 46:
          handleDeleteBranch()
          break
        default:
          return
      }
    }
    e.preventDefault()
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return loaded && (
    <Container fluid className="Review-Container">
      <Row className="Review-Row">
        <Col className="Review-Col">
          <div className="d-landscape-none mb-1">
            <Players
              className="Players"
              kifu={kifu}
              players={kifu?.sourceData?.players}
              score={score}
              reviews={reviews}
              onCreateReview={handleCreateReview}
              onReviewSelect={handleReviewSelect}
            />
          </div>
          <ReactGoban
            ref={refGoban}
            refMoveTreeContainer={refMoveTreeContainer}
            serverSocket={serverSocket}
            onStateChange={handleStateChange}
            isPlayerController={false}
          />
          <div className="Action-Bar">
            <ActionBar onMoveControl={handleMoveControl} moveNumber={moveNumber} />
          </div>
          <div className="d-landscape-none d-landscape-flex justify-content-center mt-3">
            <AnalyzeButtonsBar
              className="Game-Analyze-Button-Bar"
              reviews={reviews}
              onAnalyzeTool={handleSetAnalyzeTool}
              onPass={() => handleSetAnalyzeTool('pass')}
              onEraserDrawing={() => handleSetAnalyzeTool('eraser')}
              onCopyBranch={handleCopyBranch}
              onPasteBranch={handlePasteBranch}
              onDeleteBranch={handleDeleteBranch}
              onCreateReview={handleCreateReview}
              onReviewSelect={handleReviewSelect}
            />
          </div>
        </Col>
        <Col className="ToolBar-Col col-landscape" sm={6} lg={4} xl={4}>
          <div className="d-portrait-none d-landscape-block">
            <Players
              className="Players"
              kifu={kifu}
              players={kifu?.sourceData?.players}
              score={score}
              reviews={reviews}
              onCreateReview={handleCreateReview}
              onReviewSelect={handleReviewSelect}
            />
          </div>
          <div className="Play-Controls mt-3">
            <h5 className="text-center">
              {kifu?.phase === 'finished' ? (t(kifu?.sourceData?.black_lost ? 'White' : 'Black') + t(' wins by ') + t(kifu?.sourceData?.outcome))
                : t('Game is playing')}
            </h5>
            <div className="d-portrait-none d-landscape-flex justify-content-center">
              <AnalyzeButtonsBar
                className="Game-Analyze-Button-Bar"
                reviews={reviews}
                onAnalyzeTool={handleSetAnalyzeTool}
                onPass={() => handleSetAnalyzeTool('pass')}
                onEraserDrawing={() => handleSetAnalyzeTool('eraser')}
                onCopyBranch={handleCopyBranch}
                onPasteBranch={handlePasteBranch}
                onDeleteBranch={handleDeleteBranch}
                onCreateReview={handleCreateReview}
                onReviewSelect={handleReviewSelect}
              />
            </div>
            <div className="mt-3 mx-1">
              <div className="Move-Tree-Container" ref={refMoveTreeContainer} />
            </div>
          </div>
          <div className="mx-1 mt-3">
            <ShareVariation onShare={handleShareVariation} />
          </div>
          <div className="Chat-Container my-3 mx-1">
            <Chat
              messages={chatMessages}
              onChat={handleEnterChat}
              onMoveJump={handleMoveJump}
              onHoverVariation={handleHoverVariation}
              onLeaveVariation={handleLeaveVariation}
              onClickVariation={handleClickVariation}
              onHoverPositionText={handleHoverPositionText}
              onLeavePositionText={handleLeavePositionText}
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Kifu
