import firebase from 'firebase/app'
import { useState } from 'react'
import {
  Button, Card, Col, Container, Form, Row
} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link, useHistory } from 'react-router-dom'
import SubmitButton from 'src/components/SubmitButton'
import { updateUserProfiles } from 'src/utils/api'
import * as Yup from 'yup'
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('must be a valid email').required('is a required field'),
  password: Yup.string().required('is a required field'),
})

function Login(props) {
  const { t } = useTranslation()
  const history = useHistory()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({})

  const handleSetFormData = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleLoginSuccess = async (result) => {
    if (result.user && result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
      await updateUserProfiles({
        displayName: result.user.displayName
      })
    }
    const { location: { state } } = props
    if (state && state.from) {
      history.push(state.from.pathname)
    } else {
      history.push('/')
    }
  }

  const basicLoginHandle = async (e) => {
    e.preventDefault()
    setErrors({})
    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err) {
      const errors = {}
      for (const error of err.inner) {
        error.path = '/' + error.path
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      await firebase.auth().signInWithEmailAndPassword(formData.email, formData.password)
      const user = firebase.auth().currentUser
      if (!user.emailVerified) {
        await user.sendEmailVerification()
        await firebase.auth().signOut()
        return setErrors({
          '/email': {
            path: '/email',
            message: 'has not been confirmed. Please check Mailbox and verify'
          }
        })
      }
    } catch (err) {
      return setErrors({
        other: {
          path: 'other',
          message: err?.response?.data?.error?.message ?? err.message
        }
      })
    }
    await handleLoginSuccess({})
  }

  const [providerError, setProviderError] = useState('')
  const googleLoginHandle = e => {
    e.preventDefault()
    setProviderError('')
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithPopup(googleAuthProvider)
      .then(handleLoginSuccess)
      .catch(err => { setProviderError(err.message) })
  }

  // const facebookLoginHandle = e => {
  //   e.preventDefault()
  //   setProviderError('')
  //   const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
  //   firebase.auth().signInWithPopup(facebookAuthProvider)
  //     .then(handleLoginSuccess)
  //     .catch(err => { setProviderError(err.message) })
  // }

  return (
    <Container className="mt-5 md-5 login-container">
      <Row className="login-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="login-card">
            <Card.Body className="p-0">
              <Row>
                <Col className="d-none d-lg-block login-bg">
                </Col>
                <Col className="p-5">
                  <h2>{t('Welcome')}</h2>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>{t('Email address')}</Form.Label>
                      <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t("Email address")} />
                      {errors?.['/email']?.message && (<Form.Text className="text-danger">{t(errors?.['/email']?.path)} {t(errors?.['/email']?.message)}</Form.Text>)}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>{t('Password')}</Form.Label>
                      <Form.Control type="password" name="password" value={formData.password} onChange={handleSetFormData} placeholder={t("Password" )} />
                      {errors?.['/password']?.message && (<Form.Text className="text-danger">{t(errors?.['/password']?.path)} {t(errors?.['/password']?.message)}</Form.Text>)}
                      {errors?.other?.message && (<Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text>)}
                    </Form.Group>
                    <SubmitButton variant="success" type="submit" block onClick={basicLoginHandle}>
                      {t('Login')}
                    </SubmitButton>
                    <hr/>
                    {/* <Button variant="primary" type="button" onClick={facebookLoginHandle} block>
                      {t('Login with Facebook')}
                    </Button> */}
                    <Button variant="danger" type="button" onClick={googleLoginHandle} block>
                      {t('Login with Google')}
                    </Button>
                    {providerError && (<Form.Text className="text-danger">
                      {t(providerError)}
                    </Form.Text>)}
                    <hr/>
                    <div className="text-center">
                      <Link to="/forgot-password">{t('Forgot password?')}</Link>
                    </div>
                    <div className="text-center">
                      <Link to="/register">{t('Create an Account')}</Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
