import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import firebase from "firebase/app"
import {useEffect, useState} from "react"
import {Button, Col, Container, Form, Row, Tab, Tabs} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap"
import {useHistory, useLocation} from "react-router-dom"
import Div from 'src/components/Div'
import {Pagination, PuzzleCard} from "src/components/ListingView"
import {countPuzzles, listPuzzles} from "src/utils/api"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Listing(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const query: any = useQuery()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [limit] = useState<number>(12)
  const [page, setPage] = useState<number>(parseInt(query.get('page') ?? 1))
  const [view, setView] = useState<string>(query.get('view') ?? 'new')
  const [difficulty, setDifficulty] = useState<string>('')
  const [order, setOrder] = useState<string>('')

  useEffect(() => {
    setPage(parseInt(query.get('page') ?? 1))
    setDifficulty(query.get('difficulty') ?? '')
    setOrder(query.get('order') ?? '')
    setView(query.get('view') ?? 'new')
  }, [query])

  useEffect(() => {
    let isSubscribed = true
    const filter: any = { where: {
      official: view === 'official'
    } }
    if (difficulty) {
      filter.where = {
        ...filter.where,
        level: difficulty,
      }
    }
    countPuzzles(filter).then(data => {
      if (!isSubscribed) {
        return
      }
      setLoaded(true)
      setTotal(data.count)
    })
    return () => {
      isSubscribed = false
    }
  }, [difficulty, view])

  useEffect(() => {
    const user = firebase.auth().currentUser
    let isSubscribed = true
    const params: any = {
      filter: {
        where: {
          official: view === 'official'
        },
        include: [
          {
            scope: {
              where: {
                uid: user?.uid
              }
            },
            relation: 'puzzleAnswers'
          }
        ],
        limit: limit,
        skip: (page - 1) * limit,
        order: order === 'oldest' ? 'createdAt ASC' : 'createdAt DESC'
      }
    }
    if (difficulty) {
      params.filter.where = {
        ...params.filter.where,
        level: difficulty,
      }
    }
    listPuzzles(params).then(data => {
      if (!isSubscribed) {
        return
      }
      setItems(data)
    })
    return () => {
      isSubscribed = false
    }
  }, [difficulty, limit, order, page, view])

  const handleFilter = (e: any) => {
    query.set('page', 1)
    query.set(e.target.name, e.target.value)
    if (!e.target.value) {
      query.delete(e.target.name)
    }
    history.push({
      search: '?' + query.toString()
    })
  }

  const handleTabSelect = (tab: any) => {
    query.set('page', 1)
    query.set('view', tab)
    history.push({
      search: '?' + query.toString()
    })
  }

  return (
    <Container className="my-3">
      <Tabs className="Tab-Transparent my-3" activeKey={view} onSelect={handleTabSelect}>
        <Tab eventKey="new" title={t('New Puzzles')}></Tab>
        <Tab eventKey="official" title={t('Official Puzzles')}></Tab>
      </Tabs>
      <div className="d-flex align-items-center justify-content-end justify-content-md-between mb-3">
        <LinkContainer to={"/puzzles/create"}>
          <Button variant="default">
            <FontAwesomeIcon icon="plus" /> {t('Create Puzzle')}
          </Button>
        </LinkContainer>
      </div>
      <Row className="mb-3">
        <Col md={6} className="mb-3 mb-md-0">
          <Form>
            <Form.Row className="align-items-center justify-content-end justify-content-md-start">
              <Col xs="auto">
                <Form.Control as="select" name="difficulty" value={difficulty} onChange={handleFilter}>
                  <option value="">{t('Difficulty')}</option>
                  <option value="1">{t('Very easy')}</option>
                  <option value="2">{t('Easy')}</option>
                  <option value="3">{t('Medium')}</option>
                  <option value="4">{t('Hard')}</option>
                  <option value="5">{t('Very hard')}</option>
                </Form.Control>
              </Col>
              <Col xs="auto">
                <Form.Control as="select" name="order" value={order} onChange={handleFilter}>
                  <option value="">{t('Sort')}</option>
                  <option value="latest">{t('Latest')}</option>
                  <option value="oldest">{t('Oldest')}</option>
                </Form.Control>
              </Col>
            </Form.Row>
          </Form>
        </Col>
        {!!total && <Col className="d-flex justify-content-end align-items-center">
          <Pagination limit={limit} total={total} page={page} />
        </Col>}
      </Row>
      <Row>
        {items.map(item => (
          <Col lg={3} md={4} xs={6} key={item.id}>
            <PuzzleCard puzzle={item} />
          </Col>
        ))}
      </Row>
      <Div show={!!total} className="d-flex justify-content-end align-items-center">
        <Pagination limit={limit} total={total} page={page} />
      </Div>
      <Div show={loaded && !total} className="m-5 text-center">
        {t('No data')}
      </Div>
    </Container>
  )
}
