import { useParams } from 'react-router-dom'
import { Col, Container, Row } from "react-bootstrap"
import { useEffect, useState } from 'react'
import { getFeed } from 'src/utils/api'
import { KifuPost, UserInfo } from 'src/components/Feed'
import PuzzlePost from 'src/components/Feed/PuzzlePost'
import ReviewPost from 'src/components/Feed/ReviewPost'

export default function Details(): JSX.Element {
  const { feedId } = useParams<any>()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [feed, setFeed] = useState<any>(null)

  useEffect(() => {
    if (!feedId) {
      return
    }
    let isSubscribed = true
    const params = {
      filter: {
        include: [
          'user',
          {
            relation: 'puzzle',
            scope: {
              include: [{
                relation: 'puzzleAnswers',
                scope: {
                  include: [{
                    relation: 'user'
                  }]
                }
              }]
            }
          },
          {
            relation: 'kifu'
          }
        ]
      }
    }
    getFeed(feedId, params).then(feed => {
      if (!feed || !isSubscribed) {
        return
      }
      setFeed(feed)
      setLoaded(true)
    })
    return () => {
      isSubscribed = false
    }
  }, [feedId])

  return loaded ? (
    <Container className="my-3 Home">
      <Row>
        <Col md={4} lg={3} className="d-none d-md-block">
          <div className="sticky-top">
            <UserInfo uid={feed.uid} hideProfileLink />
          </div>
        </Col>
        <Col>
          {feed.type === 'kifu' && feed.kifu && <KifuPost item={feed} action={feed.action} />}
          {feed.type === 'review' && feed.kifu && <ReviewPost item={feed} action={feed.action} />}
          {feed.type === 'puzzle' && feed.puzzle && <PuzzlePost item={feed} />}
        </Col>
      </Row>
    </Container>
  ) : <></>
}
