import PropTypes from 'prop-types'
import { Image, ListGroup } from "react-bootstrap"
import { useLocation } from 'react-router-dom'

function SideBar({ selectIcon }) {
  const location = useLocation()

  return (
    <ListGroup key={location?.pathname} className="mb-3">
      <ListGroup.Item>
        <Image src="https://vietnamchess.vn/images/stories/logo/baduk_002.jpg" className='w-100'/>
      </ListGroup.Item>
      <ListGroup.Item>
        <h4>Giải vô địch cờ vây quốc gia năm 2021</h4>
      </ListGroup.Item>
      <ListGroup.Item>
        Giải vô địch cờ vây quốc gia năm 2021 được tổ chức tại Thanh Hóa từ ngày 17 đến 23/12/2021, các vận động viên thi đấu 2 thể loại cờ tiêu chuẩn và cờ nhanh.
      </ListGroup.Item>
      <ListGroup.Item>
        Thông tin chi tiết điều lệ giải vui lòng
        xem <a href="https://vietnamchess.vn/index.php/vi/chessnews/tournament-news/19-national-events/champion-nat-events/2077-vie-baduk-2021-1" target="_blank" rel="noreferrer">ở đây</a>
      </ListGroup.Item>
    </ListGroup>
  )
}

SideBar.propTypes = {
  selectIcon: PropTypes.string,
  onSelect: PropTypes.func
}

SideBar.defaultProps = {
  selectIcon: 'angle-right',
  onSelect: () => {}
}

export default SideBar
