import {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroller'
import {KifuPost} from 'src/components/Feed'
import {listKifu} from 'src/utils/api'

export default function Feed(): JSX.Element {
  const limit = 5
  const [feedItems, setFeedItems] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false)
  const [moreLoading, setMoreLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [include] = useState<any>([
    'user'
  ])

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)
    listKifu({
      filter: {
        where: {
          categoryId: process.env.REACT_APP_CATEGORY_CUP_LS_2021,
          createdAt: { lte: new Date() }
        },
        order: 'createdAt DESC',
        limit,
        include
      }
    }).then(data => {
      if (!isSubscribed) {
        return
      }
      setPage(1)
      setLoading(false)
      setFeedItems(data)
      setHasMoreItems(data.length >= limit)
    })
    return () => {
      isSubscribed = false
    }
  }, [include])

  const handleLoadMore = () => {
    if (moreLoading) {
      return
    }
    if (!feedItems.length) {
      return setHasMoreItems(false)
    }

    setMoreLoading(true)
    listKifu({
      filter: {
        where: {
          categoryId: process.env.REACT_APP_CATEGORY_CUP_LS_2021,
          createdAt: { lte: new Date() }
        },
        order: 'createdAt DESC',
        skip: page * limit,
        limit,
        include
      }
    }).then(async (data: any) => {
      if (data.length < limit) {
        setHasMoreItems(false)
      }
      setFeedItems([
        ...feedItems,
        ...data
      ])
      setMoreLoading(false)
      setPage(page + 1)
    })
  }

  return (
    <div className="position-relative">
      {loading && <div className="Loading-Container position-absolute">
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>}
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMoreItems}
        loader={<div key={feedItems.length} className="position-absolute Loading-More-Container">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      >
        {!!feedItems.length && feedItems.map(item => (
          <div key={item.id}>
            <KifuPost item={{
              ...item,
              kifuId: item.id,
              kifu: item,
            }} action={'create_kifu'} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}
