import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import firebase from "firebase/app"
import moment from "moment"
import {useEffect, useRef, useState} from "react"
import {Card, Col, Container, Dropdown, Image, Row} from "react-bootstrap"
import Dialog from 'react-bootstrap-dialog'
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap"
import {useHistory} from "react-router-dom"
import {toast, ToastContainer} from 'react-toastify'
import {clearCacheUserProfiles, UserState} from "src/models/UserModel"
import {deleteKifu} from "src/utils/api"
import blackStone from "../../assets/img/black.png"
import whiteStone from "../../assets/img/white.png"
import {Transformation} from "../CloudinaryImage"
import {User} from "../Model"
import MiniGoban from "../ReactGoban/MiniGoban"
import {getUserRating} from "../ReactGoban/utils"
import AvatarImage from "./AvatarImage"

interface PostProps {
  item: any,
  action?: string,
}

export default function ReviewPost(props: PostProps): JSX.Element {
  const { t } = useTranslation()
  const { item, action } = props
  const history = useHistory()
  const refGoban = useRef<any>(null)
  const [config] = useState<any>({
    handicap: item?.kifu?.handicap ?? 0,
    free_handicap_placement: item?.kifu?.freeHandicapPlacement ?? false,
  })
  const isOwner: boolean = firebase.auth().currentUser?.uid === item?.uid
  let dialog:any

  useEffect(() => {
    if (!refGoban) {
      return
    }
    refGoban?.current?.syncTrunkMoves(item?.kifu?.moves ?? [])
  }, [item?.kifu?.moves, refGoban])

  const handleDelete = () => {
    dialog.show({
      body: t('Are you sure you wish to delete this review?'),
      bsSize: 'medium',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(async () => {
          try {
            await deleteKifu(item?.kifu?.id)
            clearCacheUserProfiles(item?.kifu?.uid)
            history.push('/feed/reviews')
          } catch (err) {
            toast(t(err?.response?.data?.error?.message || 'Something went wrong'), { type: 'error', autoClose: 4000 })
          }
        })
      ],
    })
  }

  return (
    <Card className="Post-Card mb-3">
      <User data={item.user}>
        {(user: UserState) => (
          <Card.Body className="Post-Header d-flex flex-row">
            <div className="Avatar mr-3">
              {!!user.stageName ? <Image className="w-100" src={user.defaultAvatar} roundedCircle /> : <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                <AvatarImage className="w-100 rounded-circle" user={user}>
                  <Transformation width="80" height="80" gravity="auto" crop="fill" />
                </AvatarImage>
              </LinkContainer>}
            </div>
            <div className="Username d-flex justify-content-between align-items-center w-100">
              <div>
                <div>
                  {!!user.stageName ? (user.stageName || user.displayName) : <LinkContainer to={"/users/" + (user?.username || user.uid)}>
                    <a href={"/users/" + (user?.username || user.uid)} className="UserLink">{user.displayName}</a>
                  </LinkContainer>}
                  [{user?.rank ?? '?'}]
                  <span className="text-muted ml-2">{t(item?.action)}</span>
                </div>
                <div className="text-muted">
                  {moment(item.createdAt).fromNow()}
                </div>
              </div>
              {isOwner && item?.kifu?.id && <Dropdown className="More-DropDown" alignRight>
                <Dropdown.Toggle className="rounded-circle px-2 py-1" variant="default">
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleDelete} disabled>{t('Delete review')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>}
            </div>
            {isOwner && <Dialog ref={(el) => { dialog = el }} />}
            {isOwner && <ToastContainer />}
          </Card.Body>
        )}
      </User>
      <LinkContainer to={action === 'create_kifu' ? ('/kifu/' + item?.kifuId) : ('/reviews/' + item?.reviewId)}>
        <Container className="Post-Content text-light position-relative">
          <Row>
            <Col className="d-flex align-items-center my-2">
              <img className="Stone mr-2 border rounded-circle bg-light border-light" src={blackStone} alt="" />
              {item?.kifu?.sourceData?.players?.black?.username}[{item?.kifu?.sourceData?.players?.black?.rank ?? getUserRating(item?.kifu?.sourceData?.players?.black || {}).rank_label}]
              {item?.kifu?.sourceData?.ended && !item?.kifu?.sourceData?.black_lost && <FontAwesomeIcon className="ml-2 text-warning" icon="trophy" />}
            </Col>
          </Row>
          <Row>
            <Col sm={2}></Col>
            <Col sm={8} className="p-sm-0">
              <MiniGoban width={item?.kifu?.width || 19} height={item?.kifu?.height || 19} ref={refGoban} config={config} />
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-end my-2">
              {item?.kifu?.sourceData?.ended && !item?.kifu?.sourceData?.white_lost && <FontAwesomeIcon className="mr-2 text-warning" icon="trophy" />}
              {item?.kifu?.sourceData?.players?.white?.username}[{item?.kifu?.sourceData?.players?.white?.rank ?? getUserRating(item?.kifu?.sourceData?.players?.white || {}).rank_label}]
              <img className="Stone ml-2 border rounded-circle bg-light border-light" src={whiteStone} alt="" />
            </Col>
          </Row>
          <div className="Review-Signal position-absolute border p-2 text-danger border-danger font-weight-bold">{t('Review')}</div>
        </Container>
      </LinkContainer>
      <Card.Body className="d-flex align-items-center justify-content-between">
        <div>
          <FontAwesomeIcon className="mt-1 text-danger" icon="heart" /> {item?.review?.totalLikes || 0} {t(item?.review?.totalLikes === 1 ? 'Like' : 'Likes')}
        </div>
        <div className="d-none d-sm-block">
          <FontAwesomeIcon className="mt-1 text-success" icon="comment" /> {item?.review?.totalMessages || 0} {t(item?.review?.totalMessages === 1 ? 'Message' : 'Messages')}
        </div>
        <div>
          <FontAwesomeIcon className="mt-1 text-success" icon="eye" /> {item?.review?.totalViews || 0} {t(item?.review?.totalViews === 1 ? 'Total View' : 'Total Views')}
        </div>
      </Card.Body>
    </Card>
  )
}
