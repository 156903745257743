import firebase from "firebase/app"
import "firebase/firestore"
import { Col, Container, Row, ListGroup, Form, Button, Alert, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ToastContainer, toast } from 'react-toastify'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getUserProfiles, setUserProfiles, UserState } from "src/models/UserModel"
import { updateUserProfiles, upload } from "src/utils/api"
import { CloudinaryImage, Transformation } from "src/components/CloudinaryImage"

export default function Setting(): JSX.Element {
  const { t } = useTranslation()
  const user = firebase.auth().currentUser
  const [username, setUsername] = useState<string>('')
  const [disableChangeUsername, setDisableChangeUsername] = useState<boolean>(true)
  const [displayName, setDisplayName] = useState<string>('')
  const [stageName, setStageName] = useState<string>('')
  const [avatar, setAvatar] = useState<any>(null)
  const [error, setError] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (!user) {
      return
    }
    getUserProfiles(user.uid).then((profile: UserState) => {
      setUsername(profile.username ?? '')
      setDisplayName(profile.displayName)
      setStageName(profile?.stageName || '')
      setAvatar(profile?.avatar || null)
      if (!profile.username) {
        setDisableChangeUsername(false)
      }
    })
  }, [user])

  const handleSubmitProfile = async (e: any) => {
    e.preventDefault()
    if (!user || submitting) {
      return
    }
    setSubmitting(true)
    setError('')
    const data: any = {
      displayName,
      stageName,
    }
    if (avatar) {
      data.avatar = avatar
    }
    if (!disableChangeUsername && username) {
      data.username = username
    }
    try {
      await updateUserProfiles(data)
      user.updateProfile({ displayName })
      setUserProfiles(user.uid, data)
      toast(t('Update Successfully'), { type: 'info' })
    } catch (err) {
      if (err?.response?.data?.error?.details?.[0]?.path === '/username') {
        setError(t('The username is not valid. Only characters A-Z, a-z, 0-9, "_" and "-" are acceptable. And text length must be from 5 to 24'))
      } else if (err?.response?.data?.error?.message) {
        setError(t(err?.response?.data?.error?.message))
      } else {
        setError(t('Something went wrong'))
      }
    }
    setSubmitting(false)
  }

  const handleUploadAvatar = async (e: any) => {
    e.preventDefault()
    if (!e.target.files.length) {
      return
    }
    try {
      const { data } = await upload(e.target.files[0])
      if (!data?.files?.[0]?.public_id) {
        throw new Error('Upload error. Please check file and try again')
      }
      setAvatar(data.files[0])
    } catch (err) {
      setError(t(err.message))
    } 
  }

  if (!user) {
    return <></>
  }

  return (
    <Container className="my-3">
      <Row>
        <Col md={4} lg={3}>
          <ListGroup className="mb-3">
            <ListGroup.Item className="d-flex justify-content-between align-items-center" action active>
              <span>
                <FontAwesomeIcon icon="user" className="mr-3" />
                {t('User Information')}
              </span>
              <FontAwesomeIcon icon="angle-right" />
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-center" action disabled>
              <span>
                <FontAwesomeIcon icon="lock" className="mr-3" />
                {t('Security')}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-center" action disabled>
              <span>
                <FontAwesomeIcon icon="wrench" className="mr-3" />
                {t('Board Settings')}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-center" action disabled>
              <span>
                <FontAwesomeIcon icon="bars" className="mr-3" />
                {t('Other Settings')}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmitProfile}>
                <Form.Group controlId="formUsername">
                  <Form.Label>{t('Username')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Username')} value={username} onChange={e => setUsername(e.target.value)} disabled={disableChangeUsername} />
                </Form.Group>
                <Form.Group controlId="formDisplayName">
                  <Form.Label>{t('Display Name')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Display Name')} value={displayName} onChange={e => setDisplayName(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formStageName">
                  <Form.Label>{t('Stage Name (This name will show in Review Kifu)')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Stage Name (You can ignore this)')} value={stageName} onChange={e => setStageName(e.target.value)} />
                </Form.Group>
                {!!avatar && (typeof avatar === 'object') && <Form.Group controlId="formAvatar">
                  <CloudinaryImage className="img-thumbnail" cloudName={avatar.cloud_name} publicId={avatar.public_id}>
                    <Transformation width="100" height="100" gravity="auto" crop="fill" />
                  </CloudinaryImage>
                </Form.Group>}
                <Form.Group controlId="formStageName">
                  <Form.Label>{t('Avatar')}</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={handleUploadAvatar} />
                  <Alert className="mt-3" variant="danger" show={!!error}>{error}</Alert>
                </Form.Group>
                <div className="text-center">
                  <Button variant="success" type="submit" className="pl-4 pr-5" disabled={submitting}>
                    <FontAwesomeIcon icon="pen" className="mr-3" /> {t('Update')}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  )
}
