import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ActionBar({ moveNumber, onMoveControl }) {
  return (
    <>
      <span className="icons"></span>
      <span className="controls">
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('first')}><FontAwesomeIcon icon="fast-backward" /></Button>
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('prev10')}><FontAwesomeIcon icon="backward" /></Button>
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('prev')}><FontAwesomeIcon icon="step-backward" /></Button>
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('next')}><FontAwesomeIcon icon="step-forward" /></Button>
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('next10')}><FontAwesomeIcon icon="forward" /></Button>
        <Button size="sm" variant="default" className="move-control" onClick={() => onMoveControl('last')}><FontAwesomeIcon icon="fast-forward" /></Button>
      </span>
      <span className="move-number">
        <span className="d-none d-xl-flex">Move {moveNumber}</span>
      </span>
    </>
  )
}

ActionBar.propTypes = {
  moveNumber: PropTypes.number,
  onMoveControl: PropTypes.func
}

ActionBar.defaultProps = {
  moveNumber: 0,
  onMoveControl: () => {}
}

export default ActionBar
