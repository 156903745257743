const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'kifu-516b3.firebaseapp.com',
  projectId: 'kifu-516b3',
  storageBucket: 'kifu-516b3.appspot.com',
  messagingSenderId: '416429228614',
  appId: '1:416429228614:web:ec59383a75527c53aa8047',
  measurementId: 'G-HQJ0PG1L77',
  databaseURL: 'https://kifu-516b3-default-rtdb.asia-southeast1.firebasedatabase.app',
};

export default config;
