import { ReactNode, useState, useEffect } from "react"
import { getDefaultAvatar, getUserProfiles, ranks, UserState } from "src/models/UserModel"

interface UserProps {
  uid?: string,
  data?: any,
  children?: (userState: UserState, ready?: boolean) => ReactNode
}

const shortDisplayName = (displayName: string): string => {
  if (displayName.length > 20) {
    return displayName.substr(0, 20) + '...'
  }
  return displayName
}

export default function User({ uid, data, children }: UserProps): JSX.Element {
  const [user, setUser] = useState<UserState>({
    ...data,
    uid: data?.uid ?? uid ?? '',
    rank: data?.rankId ? ranks[data?.rankId] : '?',
    displayName: shortDisplayName(data?.displayName ?? ''),
    avatar: data?.avatar,
    defaultAvatar: getDefaultAvatar(data?.displayName ?? '', '')
  })
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    if (!uid) {
      return
    }
    getUserProfiles(uid).then(user => {
      if (!isSubscribed) {
        return
      }
      setReady(true)
      setUser(user)
    })
    return () => {
      isSubscribed = false
    }
  }, [uid])

  return (
    <>
      {children && children(user, ready)}
    </>
  )
}
