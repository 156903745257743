import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/database"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { Feed, CreatePost, UserInfo } from "src/components/Feed"
import { listUserProfiles } from "src/utils/api"

export default function UserWall(): JSX.Element {
  const { username }: { username: string} = useParams()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [user, setUser] = useState<any>(null)
  const [filterCondition, setFilterCondition] = useState<any>({})
  const currentUser = firebase.auth().currentUser
  const history = useHistory()

  useEffect(() => {
    if (!username) {
      return
    }
    let isSubscribed = true
    listUserProfiles({
      filter: { where: { or: [
        {username},
        {uid: username}
       ] } }
    }).then(async (data) => {
      if (!isSubscribed) {
        return
      }
      let user = null
      if (!data.length) {
        return history.replace('/404')
      }
      user = data[0]
      if (!isSubscribed) {
        return
      }
      if (user) {
        setUser(user)
        const condition: any = { uid: user.uid }
        if (user.stageName) {
          condition.type = {
            neq: 'review'
          }
        }
        setFilterCondition(condition)
        setLoaded(true)
      }
    }).catch(err => {
      if (err?.response?.status === 404) {
        return history.replace('/404')
      }
      return history.replace('/error')
    })
    return () => {
      isSubscribed = false
    }
  }, [history, username])

  return loaded ? (
    <Container className="my-3 Home">
      {user && <Row>
        <Col md={4} lg={3} className="d-none d-md-block">
          <div className="sticky-top">
            <UserInfo uid={user.uid} hideProfileLink />
          </div>
        </Col>
        <Col>
          <div className="d-md-none">
            <UserInfo uid={user.uid} hideProfileLink />
          </div>
          {currentUser && user.uid === currentUser.uid && <CreatePost onDone={(review: any) => history.push('/reviews/' + review.id)} />}
          <Feed filter={{ where: filterCondition, order: 'createdAt DESC' }} />
        </Col>
      </Row>}
    </Container>
  ) : <></>
}
