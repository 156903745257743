import { Image } from "react-bootstrap"
import { UserState } from "src/models/UserModel"
import { CloudinaryImage } from "../CloudinaryImage"

interface AvatarImageProps {
  user: UserState,
  [x: string]: any;
}

export default function AvatarImage(props: AvatarImageProps): JSX.Element {
  const { user } = props
  if (user.avatar) {
    return (
      <CloudinaryImage {...props} cloudName={user.avatar.cloud_name} publicId={user.avatar.public_id} />
    )
  }
  const newProps = {...props}
  delete newProps.children
  return (
    <Image {...newProps} src={user.defaultAvatar} />
  )
}
