import ReactResizeDetector from 'react-resize-detector'
import Dialog from 'react-bootstrap-dialog'
import { useRef, useEffect, useCallback, useState, useImperativeHandle, forwardRef, RefObject } from 'react'
import { GobanCore, GobanConfig, GobanHooks, AnalysisTool } from '../../libs/goban/src/GobanCore'
import { GobanCanvas, GobanCanvasConfig } from '../../libs/goban/src/GobanCanvas'
import { MoveTree } from '../../libs/goban/src/MoveTree'
import { AdHocFormat } from 'src/libs/goban/src/AdHocFormat'
import { GoMath } from 'src/libs/goban/src/GoMath'
import { useTranslation } from "react-i18next"
import { parsePosition } from './utils'
import './style.scss'

const hooks:GobanHooks = {
  getCoordinateDisplaySystem: () => 'A1',
  getCDNReleaseBase: () => '',
  getShowMoveNumbers: () => true,
  getShowVariationMoveNumbers: () => false,
};
GobanCore.setHooks(hooks)

const base_config:GobanConfig = {
  interactive: true,
  mode: 'setup',
  square_size: 'auto',
  draw_top_labels: true,
  draw_left_labels: true,
  draw_right_labels: true,
  draw_bottom_labels: true,
  server_socket: {
    connected: true,
    send: (eventName:string, ...args:object[]) => {},
    emit: (eventName:string, ...args:object[]) => {},
    on: (eventName:string, listener:Function) => {},
    off: (eventName:string, listener:Function) => {},
    once: (eventName:string, listener:Function) => {},
  }
}

type ViewMode = 'portrait'|'landscape'|'square'
const computeViewMode = () : ViewMode => {
  const h = window.innerHeight || 1
  const w = window.innerWidth || 1
  const aspect_ratio = w / h

  if (aspect_ratio === 1) {
    return 'square'
  }

  return aspect_ratio >= 1 ? 'landscape' : 'portrait'
}

interface ReactGobanProps {
  refSelf?: any,
  refMoveTreeContainer?: RefObject<HTMLDivElement>,
  reviewId?: number | string,
  gamedata?: any,
  config?: any,
  serverSocket?: any,
  isPlayerController?: boolean,
  onStateChange?: Function
}

function ReactGoban(props:ReactGobanProps):JSX.Element {
  const { t } = useTranslation()
  const { refSelf, refMoveTreeContainer, gamedata, isPlayerController, onStateChange, serverSocket, config: moreConfig, reviewId } = props
  const refGoban = useRef<HTMLDivElement>(null)
  const refGobanContainer = useRef<HTMLDivElement>(null)
  const [loaded, setLoaded] = useState(false)
  let goban = useRef<GobanCanvas>()
  let dialog:any
  let tempMove:MoveTree|null

  if (serverSocket) {
    base_config.server_socket = {
      ...base_config.server_socket,
      ...serverSocket
    }
  }

  const recenterGoban = () => {
    if (!goban?.current || !refGoban?.current || !refGobanContainer?.current) {
      return
    }
    const m = goban.current.computeMetrics()
    refGoban.current.style.top = (Math.ceil(refGobanContainer.current.offsetHeight - m.height) / 2) + 'px'
    refGoban.current.style.left = (Math.ceil(refGobanContainer.current.offsetWidth - m.width) / 2) + 'px'
  }

  let timeoutResizeDebounce:any
  const handleResize = useCallback((noDebounce: boolean = false, skipStateUpdate: boolean = false) => {
    if (!goban || !goban.current || !refGobanContainer.current) {
      return;
    }
    if (timeoutResizeDebounce) {
      clearTimeout(timeoutResizeDebounce)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutResizeDebounce = null
    }
    if (computeViewMode() === 'landscape') {
      if (refGobanContainer.current.style.minHeight !== `initial`) {
        refGobanContainer.current.style.minHeight = `initial`;
      }
    } else {
      let w = window.innerWidth + 10;
      if (refGobanContainer.current.style.minHeight !== `${w}px`) {
        refGobanContainer.current.style.minHeight = `${w}px`;
      }
    }
    if (!noDebounce) {
      timeoutResizeDebounce = setTimeout(() => handleResize(true), 50)
      recenterGoban()
      return
    }
    goban.current.setSquareSizeBasedOnDisplayWidth(
      Math.min(refGobanContainer.current.offsetWidth, refGobanContainer.current.offsetHeight)
    )
    recenterGoban()
  }, [goban, refGobanContainer])

  const handleStateChange = useCallback(() => {
    onStateChange && onStateChange({
      moveNumber: goban?.current?.engine?.getMoveNumber(),
      moveText: goban?.current?.engine?.cur_move?.text || '',
      score: goban?.current?.engine?.computeScore(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateGoban = useCallback(() => {
    handleResize()
    handleStateChange()
  }, [handleResize, handleStateChange])

  useEffect(() => {
    const config:GobanCanvasConfig = Object.assign({}, base_config, {
      board_div: refGoban.current || undefined,
      move_tree_container: refMoveTreeContainer?.current || undefined,
      display_width: refGobanContainer?.current?.offsetWidth,
      ...moreConfig
    })

    if (!config.game_id) {
      config.review_id = reviewId ?? 1
    }
    config.isPlayerController = () => isPlayerController || false

    goban?.current?.destroy()
    if (gamedata) {
      config.handicap = gamedata?.handicap || 0
      config.komi = gamedata?.komi || 6.5
      config.rules = gamedata?.rules || 'japanese'
      config.free_handicap_placement = gamedata?.free_handicap_placement || false
      config.width = gamedata?.width || 19
      config.height = gamedata?.height || 19
      config.superko_algorithm = gamedata?.superko_algorithm || 'csk'
      if (gamedata?.moves?.length) {
        config.moves = gamedata.moves.map((move: { blur: any }[]) => ({ x: move[0], y: move[1], timedelta: move[2], blur: move[4]?.blur }))
      }
      goban.current = new GobanCanvas(config, {
        ...config,
        black: gamedata.black,
        white: gamedata.white,
        clock: gamedata.clock,
        time_control: gamedata.time_control,
        pause_control: gamedata.pause_control,
      } as AdHocFormat)
      if (gamedata?.score) {
        goban.current.showScores(gamedata.score)
      }
    } else {
      goban.current = new GobanCanvas(config)
    }
    setLoaded(true)
    handleUpdateGoban()

    goban.current.on('update', handleUpdateGoban)

    return () => {
      goban?.current?.destroy()
      goban?.current?.off('update', handleUpdateGoban)
    }
  }, [refGoban, refMoveTreeContainer, gamedata, isPlayerController, moreConfig, handleUpdateGoban, reviewId])

  useImperativeHandle(refSelf, () => ({
    engine() {
      return goban?.current?.engine
    },
    on(event: any, listener: (arg?: any) => any) {
      goban?.current?.on(event, listener)
    },
    off(event: any, listener: (arg?: any) => any) {
      goban?.current?.off(event, listener)
    },
    setCorrectAnswer() {
      if (!goban.current) {
        return
      }
      goban.current.engine.cur_move.wrong_answer = false
      goban.current.engine.cur_move.correct_answer = !goban.current.engine.cur_move.correct_answer
      goban.current.move_tree_redraw()
    },
    setIncorrectAnswer() {
      if (!goban.current) {
        return
      }
      goban.current.engine.cur_move.correct_answer = false
      goban.current.engine.cur_move.wrong_answer = !goban.current.engine.cur_move.wrong_answer
      goban.current.move_tree_redraw()
    },
    exportAsPuzzle() {
      return goban?.current?.engine?.exportAsPuzzle()
    },
    syncToCurrentReviewMove() {
      if (goban?.current?.engine?.cur_review_move) {
        goban?.current?.engine.jumpTo(goban?.current?.engine.cur_review_move)
      }
    },
    doneLoadingReview(status:boolean) {
      goban?.current?.doneLoadingReview(status)
    },
    emit(event: any, arg?: any) {
      goban?.current?.emit(event, arg)
    },
    showMessage(msg:string, timeout?:number) {
      goban?.current?.showBoardMessage(msg, timeout)
    },
    setMode(mode: any, dont_jump_to_official_move?:boolean) {
      goban?.current?.setMode(mode, dont_jump_to_official_move)
    },
    setIsPlayerController(value: boolean) {
      goban?.current?.setIsPlayerController(value)
    },
    sendChat(body:string, type:string) {
      goban?.current?.sendChat(body, type)
    },
    jumpToMove(line:any) {
      if (!goban?.current) {
        return
      }
      if ('move_number' in line) {
        if (!goban?.current?.isAnalysisDisabled()) {
          goban?.current?.setMode('analyze');
        }
        goban?.current?.engine?.followPath(line.move_number, '')
        goban?.current?.redraw()
        if (goban?.current?.isAnalysisDisabled()) {
          goban?.current?.updatePlayerToMoveTitle()
        }
        goban?.current?.emit('update')
      }
      if ('from' in line) {
        if (goban?.current?.isAnalysisDisabled()) {
          goban?.current?.setMode('analyze')
        }
        goban?.current?.engine?.followPath(line.from, line.moves)
        goban?.current?.syncReviewMove()
        goban?.current?.drawPenMarks(goban?.current?.engine?.cur_move?.pen_marks)
        goban?.current?.redraw()
        goban?.current?.emit('update')
      }
    },
    setAnalyzeTool(tool:AnalysisTool, subtool:string) {
      goban?.current?.setAnalyzeTool(tool, subtool)
    },
    updateMoveText(text:string) {
      if (!goban?.current?.engine) {
        return
      }
      goban.current.engine.cur_move.text = text
      goban?.current?.move_tree_redraw()
    },
    setMoveText(text:string) {
      goban?.current?.syncReviewMove(undefined, text)
      goban?.current?.redraw(true)
    },
    pass() {
      goban?.current?.pass()
    },
    submitMove() {
      goban?.current?.submit_move && goban?.current?.submit_move()
    },
    clearAnalysisDrawing() {
      goban?.current?.syncReviewMove({"clearpen": true})
      goban?.current?.clearAnalysisDrawing()
    },
    copyBranch(callback:Function) {
      callback(goban?.current?.engine?.cur_move)
    },
    clearInitialStatePlace() {
      if (!goban?.current?.engine) {
        return
      }
      for (let x = 0; x < goban?.current?.engine.width; x++) {
        for (let y = 0; y < goban?.current?.engine.height; y++) {
          goban?.current?.engine?.initialStatePlace(x, y, 0)
        }
      }
      goban.current.engine.resetMoveTree()
    },
    initialStatePlace(x:number, y:number, color:any, dont_record_placement?:boolean) {
      return goban?.current?.engine?.initialStatePlace(x, y, color, dont_record_placement)
    },
    place(x:number, y:number, checkForKo?:boolean, errorOnSuperKo?:boolean, dontCheckForSuperKo?:boolean, dontCheckForSuicide?:boolean, isTrunkMove?:boolean) {
      return goban?.current?.engine?.place(x, y, checkForKo, errorOnSuperKo, dontCheckForSuperKo, dontCheckForSuicide, isTrunkMove)
    },
    syncTrunkMoves(moves:Array<any>) {
      if (!goban.current) {
        return
      }
      const isLastOfficialMove:boolean = goban?.current?.engine?.isLastOfficialMove()
      const curMove:MoveTree = goban?.current?.engine?.cur_move
      goban?.current?.jumpToLastOfficialMove()
      const lastOfficialMove = goban.current.engine.last_official_move
      if (lastOfficialMove.move_number >= moves.length) {
        if (isLastOfficialMove) {
          goban?.current?.jumpToLastOfficialMove()
        } else {
          goban?.current?.engine?.jumpTo(curMove)
        }
        return // nothing to sync
      }
      for (let i = lastOfficialMove.move_number; i < moves.length; i++) {
        const newMove = moves[i]
        try {
          goban?.current?.engine?.place(newMove[0], newMove[1], false, false, false, true, true)
        } catch (err) {
          console.warn(err)
        }
      }
      goban?.current?.engine?.setLastOfficialMove()
      if (isLastOfficialMove) {
        goban?.current?.jumpToLastOfficialMove()
      } else {
        goban?.current?.engine?.jumpTo(curMove)
      }
      goban?.current?.syncReviewMove()
      handleStateChange()
    },
    pasteBranch(copiedMove:MoveTree) {
      let paste = (base:MoveTree, source:MoveTree) => {
        goban?.current?.engine?.jumpTo(base)
        if (source.edited) {
          goban?.current?.engine?.editPlace(source.x, source.y, source.player, false)
        }
        else {
          goban?.current?.engine?.place(source.x, source.y, false, false, true, false, false)
        }
        const cur:MoveTree = goban?.current?.engine?.cur_move as MoveTree

        if (source.trunk_next) {
          paste(cur, source.trunk_next)
        }
        for (let branch of source.branches) {
          paste(cur, branch)
        }
      }
      try {
        const cur:MoveTree = goban?.current?.engine?.cur_move as MoveTree
        paste(cur, copiedMove)
      } catch (e) {
        dialog.showAlert('A move conflict has been detected')
      }
      goban?.current?.syncReviewMove()
      handleStateChange()
    },
    deleteBranch() {
      if (goban?.current?.engine?.cur_move?.trunk) {
        dialog.showAlert(t('The current position is not an explored branch, so there is nothing to delete'), 'medium')
        return
      }
      dialog.show({
        body: t('Are you sure you wish to remove this move branch?'),
        bsSize: 'medium',
        actions: [
          Dialog.CancelAction(),
          Dialog.OKAction(() => {
            goban?.current?.deleteBranch()
            goban?.current?.syncReviewMove()
          })
        ],
      })
    },
    moveControl(control:String) {
      switch (control) {
        case 'prev':
          goban?.current?.showPrevious()
          break
        case 'next':
          goban?.current?.showNext()
          break
        case 'prev10':
          for (let i = 0; i < 10; ++i) {
            goban?.current?.showPrevious()
          }
          break
        case 'next10':
          for (let i = 0; i < 10; ++i) {
            goban?.current?.showNext()
          }
          break
        case 'first':
          goban?.current?.showFirst()
          break
        case 'last':
          goban?.current?.jumpToLastOfficialMove()
          break
        case 'up':
          goban?.current?.prevSibling()
          break
        case 'down':
          goban?.current?.nextSibling()
          break
        default:
          return
      }
      goban?.current?.syncReviewMove()
    },
    shareAnalysis(name:string) {
      if (!goban?.current) {
        return
      }
      const diff:any = goban?.current?.engine?.getMoveDiff()
      const marks:any = {}
      let mark_ct = 0
      for (let y = 0; y < goban?.current?.height; ++y) {
        for (let x = 0; x < goban?.current?.width; ++x) {
          const pos:any = goban?.current?.getMarks(x, y)
          const marktypes = ['letter', 'triangle', 'circle', 'square', 'cross']
          for (let i = 0; i < marktypes.length; ++i) {
            if (marktypes[i] in pos && pos[marktypes[i]]) {
              let markkey = marktypes[i] === 'letter' ? pos.letter : marktypes[i]
              if (!(markkey in marks)) {
                marks[markkey] = ''
              }
              marks[markkey] += GoMath.encodeMove(x, y)
              ++mark_ct
            }
          }
        }
      }
      const analysis: any = {
        type: 'analysis',
        from: diff.from,
        moves: diff.moves,
        name: name
      }
      if (mark_ct) {
        analysis.marks = marks
      }
      if (goban?.current?.pen_marks.length) {
        analysis.pen_marks = goban?.current?.pen_marks
      }
      goban?.current?.sendChat(analysis, 'review')
    },
    createReview(reviewId: string, name: string) {
      if (!goban?.current) {
        return
      }
      const diff: any = goban?.current?.engine?.getMoveDiff()
      const review: any = {
        type: 'review',
        from: diff?.from,
        moves: diff?.moves,
        reviewId: reviewId,
        name: name
      }
      goban?.current?.sendChat(review, 'review')
    },
    hoverVariation(variation:any) {
      if (!goban?.current) {
        return
      }
      const turn = 'branch_move' in variation ? variation.branch_move - 1 : variation.from
      const moves = variation.moves
      tempMove = goban?.current?.engine?.cur_move
      goban?.current?.engine?.followPath(parseInt(turn), moves)
      if (variation.marks) {
          goban?.current?.setMarks(variation.marks)
      }
      if (variation.pen_marks) {
          goban.current.pen_marks = [].concat(variation.pen_marks)
      } else {
          goban.current.pen_marks = []
      }
      goban?.current?.redraw()
    },
    leaveVariation() {
      if (!goban?.current || !tempMove) {
        return
      }
      goban?.current?.engine.jumpTo(tempMove)
      if (goban?.current?.pen_marks.length === 0) {
        goban?.current?.disablePen()
      }
      goban?.current?.redraw()
    },
    clickVariation() {
      tempMove = null
    },
    showMarkPosition(position:string) {
      if (!goban?.current) {
        return
      }
      const pos = parsePosition(position, goban.current)
      if (pos.i >= 0) {
        goban.current.getMarks(pos.i, pos.j).chat_triangle = true
        goban.current.drawSquare(pos.i, pos.j)
      }
    },
    hideMarkPosition(position:string) {
      if (!goban?.current) {
        return
      }
      const pos = parsePosition(position, goban.current)
      if (pos.i >= 0) {
        goban.current.getMarks(pos.i, pos.j).chat_triangle = false
        goban.current.drawSquare(pos.i, pos.j)
      }
    }
  }))

  return (
    <div className="Goban-Container" ref={refGobanContainer}>
      {loaded && <ReactResizeDetector handleWidth handleHeight onResize={() => handleResize()} targetDomEl={refGobanContainer.current || undefined}/>}
      <div className="Goban" ref={refGoban} />
      <Dialog ref={(el) => { dialog = el }} />
    </div>
  )
}

ReactGoban.defaultProps = {
  onStateChange: () => {}
}

export default forwardRef((props:ReactGobanProps, ref) => <ReactGoban {...props} refSelf={ref} />)
