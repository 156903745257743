interface DivProps {
  show?: boolean,
  hide?: boolean,
  [x: string]: any,
}

export default function Div(props: DivProps): JSX.Element {
  const {
    show = true,
    hide = false,
    children
  } = props
  if (!show || hide) {
    return <></>
  }
  const newProps = {...props}
  delete newProps.show
  delete newProps.hide
  return (
    <div {...newProps}>
      {children && children}
    </div>
  )
}

export function If(props: DivProps): JSX.Element {
  const {
    show = true,
    hide = false,
    children
  } = props
  if (!show || hide) {
    return <></>
  }
  const newProps = {...props}
  delete newProps.show
  delete newProps.hide
  return (<>
    {children && children}
  </>)
}
