import firebase from 'firebase/app'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Circle, Square, Triangle, XLg } from 'react-bootstrap-icons'
import { ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { useTranslation } from "react-i18next"

function AnalyzeButtonsBar(props) {
  const {
    review,
    onAnalyzeTool, onPass, onEraserDrawing, onCopyBranch,
    onPasteBranch, onDeleteBranch, onSyncToCurrentReviewMove,
  } = props
  const { t } = useTranslation()
  const user = firebase.auth().currentUser
  const [analyzeTool, setAnalyzeTool] = useState('stone')
  const [analyzeSubtool, setAnalyzeSubtool] = useState('alternate')
  const [color, setColor] = useState('#004cff')
  const [copiedMove, setCopiedMove] = useState(null)

  const handleAnalyzeTool = (tool, subtool) => {
    onAnalyzeTool(tool, subtool)
    setAnalyzeTool(tool)
    setAnalyzeSubtool(subtool)
  }

  const handleCopyBranch = () => {
    onCopyBranch(copiedMove => {
      toast('Branch copied', { type: 'info', autoClose: 2000 })
      setCopiedMove(copiedMove)
    })
  }

  const handlePasteBranch = () => {
    onPasteBranch(copiedMove)
  }

  return (
    <div className="Game-Analyze-Button-Bar">
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" variant={analyzeTool === 'stone' && analyzeSubtool !== 'black' && analyzeSubtool !== 'white' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('stone', 'alternate')} title={t("Place alternating stones")}>
            <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/black-white.png" />
          </Button>
          <Button size="sm" variant={analyzeTool === 'stone' && analyzeSubtool === 'black' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('stone', 'black')} title={t("Place black stones")}>
            <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/black.png" />
          </Button>
          <Button size="sm" variant={analyzeTool === 'stone' && analyzeSubtool === 'white' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('stone', 'white')} title={t("Place white stones")}>
            <img className="my-1" alt="alternate" src="https://cdn.online-go.com/5.1/img/white.png" />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'letters' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'letters')} title={t("Place alphabetical labels")}><span className="p-1">A</span></Button>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'numbers' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'numbers')} title={t("Place numeric labels")}><span className="p-1">1</span></Button>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'triangle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'triangle')} title={t("Place triangle marks")}><Triangle className="md-1" /></Button>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'square' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'square')} title={t("Place square marks")}><Square className="md-1" /></Button>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'circle' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'circle')} title={t("Place circle marks")}><Circle className="md-1" /></Button>
          <Button size="sm" variant={analyzeTool === 'label' && analyzeSubtool === 'cross' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('label', 'cross')} title={t("Place X marks")}><XLg className="md-1" /></Button>
        </ButtonGroup>
      </ButtonToolbar>
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" variant="light" onClick={onPass}>Pass</Button>
        </ButtonGroup>
      </ButtonToolbar>
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" variant={analyzeTool === 'draw' ? 'primary' : 'light'} onClick={() => handleAnalyzeTool('draw', color)} title={t("Draw on the board with a pen")}><FontAwesomeIcon className="mt-1" icon="pencil-alt" /></Button>
          <Button size="sm" variant="light" onClick={onEraserDrawing} title={t("Clear pen marks")}><FontAwesomeIcon className="mt-1" icon="eraser" /></Button>
          <input type="color" title={t("Select pen color")} value={color} onChange={e => {setColor(e.target.value); handleAnalyzeTool('draw', e.target.value)}} />
        </ButtonGroup>
      </ButtonToolbar>
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" variant="light" onClick={handleCopyBranch} title={t("Copy this branch")}><FontAwesomeIcon className="mt-1" icon="clone" /></Button>
          <Button size="sm" variant="light" onClick={handlePasteBranch} title={t("Paste branch")} disabled={!copiedMove}><FontAwesomeIcon className="mt-1" icon="clipboard" /></Button>
          <Button size="sm" variant="light" onClick={onDeleteBranch} title={t("Delete branch")}><FontAwesomeIcon className="mt-1" icon="trash" /></Button>
        </ButtonGroup>
      </ButtonToolbar>
      {!!review && review?.uid !== user?.uid && <ButtonToolbar>
        <Button size="sm" variant="warning" title={t("Sync to current review move")} onClick={onSyncToCurrentReviewMove}>{t('Sync')}</Button>
      </ButtonToolbar>}
      <ToastContainer />
    </div>
  )
}

AnalyzeButtonsBar.propTypes = {
  review: PropTypes.object,
  onAnalyzeTool: PropTypes.func,
  onEraserDrawing: PropTypes.func,
  onCopyBranch: PropTypes.func,
  onPasteBranch: PropTypes.func,
  onDeleteBranch: PropTypes.func,
  onPass: PropTypes.func,
  onSyncToCurrentReviewMove: PropTypes.func,
}

AnalyzeButtonsBar.defaultProps = {
  review: null,
  onAnalyzeTool: () => {},
  onEraserDrawing: () => {},
  onCopyBranch: () => {},
  onPasteBranch: () => {},
  onDeleteBranch: () => {},
  onPass: () => {},
  onSyncToCurrentReviewMove: () => {},
}

export default AnalyzeButtonsBar
