import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Feed from './Feed'
import SideBar from './SideBar'

function Home() {
  const { t } = useTranslation()
  return (
    <Container className="my-3 Home">
      <Row>
        <Col className="d-none d-lg-block">
          <div className="sticky-top">
            <SideBar />
          </div>
        </Col>
        <Col md={8} lg={6}>
          <Feed />
        </Col>
        <Col className="d-none d-md-block">
          <div className="sticky-top">
            <h4>{t('Tham khảo')}</h4>
            <ListGroup className="mb-3">
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://vietnamchess.vn" target="_blank" rel="noreferrer">Liên đoàn cờ Việt Nam</a>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://www.facebook.com/groups/229629630634" target="_blank" rel="noreferrer">Cộng đồng cờ vây VN</a>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <a href="https://blogcovay.com" target="_blank" rel="noreferrer">Blog Cờ Vây</a>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
