import firebase from 'firebase/app'
import 'firebase/database'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { IfFirebaseAuthed } from '@react-firebase/auth'
import { Nav, Badge } from 'react-bootstrap'

function ActiveGames (props) {
  const location = useLocation()
  const [actives, setActives] = useState([])
  const [activeLink, setActiveLink] = useState('')

  useEffect(() => {
    const user = firebase.auth().currentUser
    if (!user) {
      return
    }
    firebase.database().ref('users').child(user.uid).child('activeGames').on('value', snapshot => {
      const data = snapshot.val()
      if (!data) {
        return setActives([])
      }
      const actives = Object.keys(data)
      setActives(actives)

      let index = actives.indexOf(location.pathname.split('/').pop())
      if (index < 0) {
        index = 0
      } else if (index < actives.length - 1) {
        index++
      } else if (index === actives.length - 1) {
        index = 0
      }
      setActiveLink('/games/' + actives[index])
    })

    return function cleanup() {
      firebase.database().ref('users').child(user.uid).child('activeGames').off('value')
    }
  }, [location])

  return (
    !!actives.length && <IfFirebaseAuthed>
      {() => (
        <LinkContainer to={activeLink}>
          <Nav.Link {...props}>
            <Badge variant="primary">{actives.length}</Badge>
          </Nav.Link>
        </LinkContainer>
      )}
    </IfFirebaseAuthed>
  )
}

export default ActiveGames
