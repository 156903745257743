import { Pagination as BtPagination } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"

interface PaginationProps {
  limit: number,
  total: number,
  page?: number,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Pagination({ limit, total, page = 1 }: PaginationProps): JSX.Element {
  const history = useHistory()
  const query: any = useQuery()
  const totalPages = Math.ceil(total / limit)

  const handleGotoPage = (page: number) => {
    query.set('page', page)
    history.push({
      search: '?' + query.toString()
    })
  }

  return (
    <BtPagination className="m-0">
      <BtPagination.Prev onClick={() => handleGotoPage(page - 1)} disabled={page <= 1}/>
      {page > 2 && <BtPagination.Item onClick={() => handleGotoPage(1)}>{1}</BtPagination.Item>}
      {page > 3 && <BtPagination.Item onClick={() => handleGotoPage(2)}>{2}</BtPagination.Item>}
      {page > 4 && <BtPagination.Item onClick={() => handleGotoPage(3)}>{3}</BtPagination.Item>}

      {page > 5 && <BtPagination.Ellipsis />}

      {page > 1 && <BtPagination.Item onClick={() => handleGotoPage(page - 1)}>{page - 1}</BtPagination.Item>}
      <BtPagination.Item active>{page}</BtPagination.Item>
      {page < totalPages && <BtPagination.Item onClick={() => handleGotoPage(page + 1)}>{page + 1}</BtPagination.Item>}

      {page < totalPages - 4 && <BtPagination.Ellipsis />}

      {page < totalPages - 3 && <BtPagination.Item onClick={() => handleGotoPage(totalPages - 2)} >{totalPages - 2}</BtPagination.Item>}
      {page < totalPages - 2 && <BtPagination.Item onClick={() => handleGotoPage(totalPages - 1)} >{totalPages - 1}</BtPagination.Item>}
      {page < totalPages - 1 && <BtPagination.Item onClick={() => handleGotoPage(totalPages)} >{totalPages}</BtPagination.Item>}
      <BtPagination.Next onClick={() => handleGotoPage(page + 1)} disabled={page >= totalPages} />
    </BtPagination>
  )
}
