import {ReactNode, useState} from "react"
import {Button, ButtonProps, Spinner} from "react-bootstrap"

interface SubmitButtonProps extends ButtonProps{
  children?: ReactNode
}

export default function SubmitButton(props: SubmitButtonProps): JSX.Element {
  const { children, onClick } = props
  const [submitted, setSubmitted] = useState<boolean>(true)

  const handleClick = async (e: any): Promise<void> => {
    if (!onClick) {
      return
    }
    setSubmitted(false)
    await onClick(e)
    try {
      setSubmitted(true)
    } catch {}
  }

  return (
    <Button {...props} onClick={handleClick} disabled={!submitted}>
      {!submitted && <Spinner animation="border" size="sm" className="mr-2" />}
      {children}
    </Button>
  )
}
