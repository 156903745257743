import ReactResizeDetector from 'react-resize-detector'
import { forwardRef, useEffect, useImperativeHandle, useRef, useCallback, useState } from 'react'
import { GobanCanvas, GobanCanvasConfig } from 'src/libs/goban/src/GobanCanvas'
import { PuzzleConfig } from 'src/libs/goban/src/GoEngine'
import { GoMath } from 'src/libs/goban/src/GoMath'
import { PuzzleTransform, TransformSettings } from 'src/libs/goban/src/PuzzleTransform'
import './styleminigoban.scss'
import { GobanBounds } from 'src/libs/goban/src/GobanCore'

const getBounds = (puzzle: PuzzleConfig, width: number, height: number, boardSquare?: boolean): GobanBounds => {
  const ret = {
    top: 9999,
    bottom: 0,
    left: 9999,
    right: 0,
  }

  const process = (pos: any, width: number, height: number) => {
    if (Array.isArray(pos)) {
      for (let i = 0; i < pos.length; ++i) {
        process(pos[i], width, height)
      }
      return
    }
    if (pos.x >= 0) {
      ret.left   = Math.min(pos.x, ret.left)
      ret.right  = Math.max(pos.x, ret.right)
      ret.top    = Math.min(pos.y, ret.top)
      ret.bottom = Math.max(pos.y, ret.bottom)
    }
    if (pos.marks && Array.isArray(pos.marks)) {
      for (let i = 0; i < pos.marks.length; ++i) {
        process(pos.marks[i], width, height)
      }
    }
    if (pos.branches) {
      process(pos.branches, width, height)
    }
  }

  process(GoMath.decodeMoves(puzzle?.initial_state?.black, width, height), width, height)
  process(GoMath.decodeMoves(puzzle?.initial_state?.white, width, height), width, height)
  process(puzzle.move_tree, width, height)

  if (ret.top > ret.bottom) {
    return {
      top: 0,
      bottom: height - 1,
      left: 0,
      right: width - 1
    }
  }

  const padding = 1
  ret.top = Math.max(0, ret.top - padding)
  ret.bottom = Math.min(height - 1, ret.bottom + padding)
  ret.left = Math.max(0, ret.left - padding)
  ret.right = Math.min(width - 1, ret.right + padding)

  const snap_to_edge = 3;
  if (ret.top <= snap_to_edge) {
      ret.top = 0
  }
  if (ret.bottom >= height - snap_to_edge) {
      ret.bottom = height - 1
  }
  if (ret.left <= snap_to_edge) {
      ret.left = 0
  }
  if (ret.right >= width - snap_to_edge) {
      ret.right = width - 1
  }

  if (boardSquare) {
    const w = Math.abs(ret.right - ret.left)
    const h = Math.abs(ret.bottom - ret.top)
    const incrementLength = (x: number, y: number, length: number, maxLength: number) => {
      do {
        x = Math.max(0, x - 1)
        if (y - x === length) {
          break
        }
        y = Math.min(maxLength - 1, y + 1)
      } while (y - x < length)
      return { x, y }
    }
    if (w > h) {
      const newRet = incrementLength(Math.min(ret.bottom, ret.top), Math.max(ret.bottom, ret.top), w, height)
      ret.top = newRet.x
      ret.bottom = newRet.y
    } else if (w < h) {
      const newRet = incrementLength(Math.min(ret.left, ret.right), Math.max(ret.left, ret.right), h, width)
      ret.left = newRet.x
      ret.right = newRet.y
    }
  }

  return ret
}

interface PuzzleGobanProps {
  refSelf?: any,
  id?: string,
  puzzle?: any,
  interactive?: boolean,
  boardSquare?: boolean,
  onStateChange?: Function,
}

function PuzzleGoban(props:PuzzleGobanProps):JSX.Element {
  const { refSelf, puzzle, onStateChange, interactive, boardSquare } = props
  const refGoban = useRef<HTMLDivElement>(null)
  const refGobanContainer = useRef<HTMLDivElement>(null)
  const [loaded, setLoaded] = useState(false)
  let goban = useRef<GobanCanvas>()

  const recenterGoban = () => {
    if (!goban?.current || !refGoban?.current || !refGobanContainer?.current) {
      return
    }
    const m = goban.current.computeMetrics()
    refGoban.current.style.top = (Math.ceil(refGobanContainer.current.offsetHeight - m.height) / 2) + 'px'
    refGoban.current.style.left = (Math.ceil(refGobanContainer.current.offsetWidth - m.width) / 2) + 'px'
  }

  let timeoutResizeDebounce:any
  const handleResize = useCallback((noDebounce: boolean = false, skipStateUpdate: boolean = false) => {
    if (!goban || !goban.current || !refGobanContainer.current) {
      return;
    }
    if (timeoutResizeDebounce) {
      clearTimeout(timeoutResizeDebounce)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutResizeDebounce = null
    }
    if (!noDebounce) {
      timeoutResizeDebounce = setTimeout(() => handleResize(true), 50)
      recenterGoban()
      return
    }
    goban.current.setSquareSizeBasedOnDisplayWidth(
      refGobanContainer.current.offsetWidth
    )
    recenterGoban()
  }, [goban, refGobanContainer])

  const handleStateChange = useCallback(() => {
    onStateChange && onStateChange({
      moveText: goban?.current?.engine?.cur_move?.text || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateGoban = useCallback(() => {
    handleResize()
    handleStateChange()
  }, [handleResize, handleStateChange])

  useEffect(() => {
    if (!refGoban.current) {
      return
    }
    const transformPuzzle: PuzzleTransform = new PuzzleTransform(new TransformSettings(
      false,
      Math.random() < 0.5,
      Math.random() < 0.5,
      Math.random() < 0.5,
      true
    ))
    const config: GobanCanvasConfig = {
      board_div: refGoban.current,
      square_size: 'auto',
      interactive: interactive ?? true,
      mode: 'puzzle',
      width: puzzle.width,
      height: puzzle.height,
      display_width: refGobanContainer?.current?.offsetWidth,
      initial_state: puzzle.initialState,
      initial_player: puzzle.initialPlayer,
      move_tree: puzzle.moveTree,
      puzzle_player_move_mode: puzzle.playerMoveMode,
      puzzle_opponent_move_mode: puzzle.opponentMoveMode,
      puzzle_type: puzzle.puzzleType,
      getPuzzlePlacementSetting: () => ({ mode: 'play' })
    }
    let bounds, ratio, avoidLoopInfinity = 0
    do {
      transformPuzzle.transformPuzzle(config)
      if (puzzle.width < 19) {
        break
      }
      bounds = getBounds(config, config.width ?? 19, config.height ?? 19, boardSquare)
      const width = Math.abs(bounds.right - bounds.left)
      const height = Math.abs(bounds.bottom - bounds.top)
      ratio = width / height
      if (avoidLoopInfinity++ > 10) {
        break
      }
    } while (ratio < 0.75)
    config.bounds = bounds
    goban?.current?.destroy()
    goban.current = new GobanCanvas(config)
    setLoaded(true)
    handleUpdateGoban()
    goban.current.on('update', handleUpdateGoban)

    return () => {
      goban?.current?.off('update', handleUpdateGoban)
      goban?.current?.destroy()
    }
  }, [boardSquare, handleUpdateGoban, interactive, puzzle])

  useImperativeHandle(refSelf, () => ({
    showFirst() {
      goban?.current?.showFirst()
    },
    showPrevious() {
      goban?.current?.showPrevious()
    },
    on(event: any, listener: (arg?: any) => any) {
      goban?.current?.on(event, listener)
    },
    off(event: any, listener: (arg?: any) => any) {
      goban?.current?.off(event, listener)
    },
    showMessage(msg:string, timeout?:number) {
      goban?.current?.showBoardMessage(msg, timeout)
    },
    hideMessage() {
      goban?.current?.hideBoardMessage()
    }
  }))

  return (
    <div className="MiniGoban-Container h-100" ref={refGobanContainer}>
      {loaded && <ReactResizeDetector handleWidth handleHeight onResize={() => handleResize()} targetDomEl={refGobanContainer.current || undefined}/>}
      <div className="Goban" ref={refGoban} />
    </div>
  )
}

export default forwardRef((props:PuzzleGobanProps, ref) => <PuzzleGoban {...props} refSelf={ref} />)
