import { GobanCore } from 'src/libs/goban/src/GobanCore'
import { interpolate } from '../../libs/goban/src/translate'

class Rating {
  unset: boolean = false
  rating: number = 0
  deviation: number = 0
  volatility: number = 0
  provisional: boolean = false
  rank: number = 0
  rank_label: string = ''
  partial_rank: number = 0
  partial_rank_label: string = ''
  rank_deviation_labels: Array <string> = []
  rank_deviation: number = 0
  professional: boolean = false
  bounded_rank: number = 0
  bounded_rank_label: string = ''
  partial_bounded_rank: number = 0
  partial_bounded_rank_label: string = ''
}

export const MinRank: number = 5
export const MaxRank: number = 38
export const PROVISIONAL_RATING_CUTOFF = 160

const MIN_RATING = 100
const MAX_RATING = 6000
const A = 525
const C = 23.15

export function rating_to_rank(rating: number) {
  return Math.log(Math.min(MAX_RATING, Math.max(MIN_RATING, rating)) / A) * C
}

export function getUserRating(user: any, speed: 'overall' | 'blitz' | 'live' | 'correspondence' = 'overall', size: 0 | 9 | 13 | 19 = 0) {
  let ret = new Rating()
  let ratings = user.ratings || {}
  ret.professional = user.pro || user.professional

  let key: string = speed
  if (size > 0) {
    if (speed !== 'overall') {
      key += `-${size}x${size}`
    } else {
      key = `${size}x${size}`
    }
  }

  let rating = {
    rating: 1500,
    deviation: 350,
    volatility: 0.06,
  }
  ret.unset = true
  if (key in ratings) {
    ret.unset = false
    rating = ratings[key]
  }

  ret.rating = rating.rating
  ret.deviation = rating.deviation
  ret.provisional = rating.deviation >= PROVISIONAL_RATING_CUTOFF
  ret.volatility = rating.volatility
  ret.rank = Math.floor(rating_to_rank(ret.rating))
  ret.rank_deviation = rating_to_rank(ret.rating + ret.deviation) - rating_to_rank(ret.rating)
  ret.partial_rank = rating_to_rank(ret.rating)
  ret.rank_label = rankString(ret.rank, false)
  ret.partial_rank_label = rankString(ret.partial_rank, true)
  ret.rank_deviation_labels = [
    rankString(rating_to_rank(ret.rating - ret.deviation), true),
    rankString(rating_to_rank(ret.rating + ret.deviation), true),
  ]
  ret.bounded_rank = Math.max(MinRank, Math.min(MaxRank, ret.rank))
  ret.bounded_rank_label = rankString(ret.bounded_rank)
  ret.partial_bounded_rank = Math.max(MinRank, Math.min(MaxRank, ret.partial_rank))
  ret.partial_bounded_rank_label = rankString(ret.partial_bounded_rank, true)
  if (ret.rank > (MaxRank + 1)) {
    ret.bounded_rank_label += '+'
    ret.partial_bounded_rank_label += '+'
  }

  if (ret.professional) {
    ret.rank_label = rankString(user)
    ret.bounded_rank_label = rankString(user)
    ret.partial_rank_label = ret.rank_label
    ret.rank_deviation_labels = ['', '']
  }

  return ret
}

function overall_rank(user_or_rank:any):number {
  let rank = null
  if (typeof(user_or_rank) === 'number') {
      rank = user_or_rank
  } else {
      rank = getUserRating(user_or_rank, 'overall', 0).rank
  }
  return rank
}

export function rankString(r: any, with_tenths ? : boolean) {
  let provisional = false

  if (typeof (r) === "object") {
    provisional = is_provisional(r)

    let ranking = "ranking" in r ? r.ranking : r.rank
    if (r.pro || r.professional) {
      if (ranking > 900) {
        return interpolate(pgettext("Pro", "%sp"), [(((ranking - 1000) - 36))])
      } else {
        return interpolate(pgettext("Pro", "%sp"), [((ranking - 36))])
      }
    }
    if ('ratings' in r) {
      r = overall_rank(r)
    } else {
      provisional = false
      r = ranking
    }
  }
  if (r > 900) {
    return interpolate(pgettext("Pro", "%sp"), [(((r - 1000) - 36))])
  }

  if (r < -900) {
    provisional = true
  }
  if (provisional) {
    return "?"
  }

  if (r < 30) {
    if (with_tenths) {
      r = (Math.ceil((30 - r) * 10) / 10).toFixed(1)
    } else {
      r = Math.ceil(30 - r)
    }
    return interpolate(pgettext("Kyu", "%sk"), [r])
  }

  if (with_tenths) {
    r = (Math.floor((r - 29) * 10) / 10).toFixed(1)
  } else {
    r = Math.floor(r - 29)
  }
  return interpolate(pgettext("Dan", "%sd"), [r])
}

export function is_provisional(user: any): boolean {
  let ratings = user.ratings || {}

  let rating = ratings['overall'] || {
    rating: 1500,
    deviation: 350,
    volatility: 0.06,
  }

  return rating.deviation >= PROVISIONAL_RATING_CUTOFF
}

let catalog: { [key: string]: string[] } = {}

const debug_wrap = (s: string) => s
export function pgettext(context: string, msgid: string) {
  let key = context + "" + msgid
  if (key in catalog) {
      return catalog[key][0]
  }
  return debug_wrap(msgid)
}

export function parsePosition(position:string, goban:GobanCore) {
  if (!position) {
    return { i: -1, j: -1 }
  }
  let i = "abcdefghjklmnopqrstuvwxyz".indexOf(position[0].toLowerCase())
  let j = ((goban && goban.height) || 19) - parseInt(position.substr(1))
  if (j < 0 || i < 0) {
    i = -1
    j = -1
  }
  if (i >= ((goban && goban.width) || 19) || j >= ((goban && goban.height) || 19)) {
    i = -1
    j = -1
  }
  return {i: i, j: j}
}
