import firebase from 'firebase/app'
import { getUserRating } from './utils'
import { useTranslation } from "react-i18next"
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Like } from '../Model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { getUserProfiles } from 'src/utils/api'

interface PlayersProps {
  kifu?: any,
  review?: any,
  reviews?: any,
  players?: any,
  score?: any,
  onReviewSelect?: Function,
  onCreateReview?: Function,
  [x: string]: any,
}

export default function Players(props: PlayersProps): JSX.Element {
  const {
    kifu,
    review,
    reviews = [],
    onReviewSelect = (e: any) => {},
    onCreateReview = (e: any) => {},
    players, score
  } = props
  const user = firebase.auth().currentUser
  const { t } = useTranslation()
  const [existsReviewer, setExistsReviewer] = useState(true)
  const [totalLikes, setTotalLikes] = useState<number>(0)

  useEffect(() => {
    if (!user || !kifu) {
      return
    }
    let isSubscribed = true
    getUserProfiles(user.uid).then(user => {
      if (!isSubscribed || (user?.rankId || 0) < 30) {
        return
      }
      setExistsReviewer(!!reviews.find((review: any) => review?.uid === user?.uid))
    })
    return () => {
      isSubscribed = false
    }
  }, [reviews, user, kifu])

  const onLikeChange = (liked: boolean) => {
    setTotalLikes(totalLikes + (liked ? 1 : -1))
  }

  return (<>
    {!!players && !!players.black && !!players.white && (
      <div className="players">
        <div className="black player-container">
          <div className="player-icon-clock-row d-flex align-items-center">
            {!!players.black.icon && <div className="player-icon-container mr-1" style={{backgroundImage: `url("${players.black.icon}")`}}></div>}
            <div className="black player-name-container">
              <span>
                <a href={players.black.id ? `https://online-go.com/player/${players.black.id}/${players.black.username}` : '#'} className="Player">
                  <span className="Player-username">{players.black.username}</span>
                  <span className="Player-rank">[{players.black.rank ?? getUserRating(players.black).rank_label}]</span>
                </a>
              </span>
            </div>
          </div>
          {!!score && !!score.black && <div className="score-container">
            <div className="captures">
              <span className="num-captures-container">
                <span className="num-captures-count">{score.black.prisoners}</span>
                <span className="num-captures-units"> {t(score.black.prisoners === 1 ? 'capture' : 'captures')}</span>
              </span>
            </div>
            {!!score.black.komi && <div className="komi">
              +{score.black.komi}
            </div>}
          </div>}
        </div>
        <div className="white player-container">
          <div className="player-icon-clock-row d-flex align-items-center">
          {!!players.white.icon && <div className="player-icon-container mr-1" style={{backgroundImage: `url("${players.white.icon}")`}}></div>}
            <div className="white player-name-container">
              <span>
                <a href={players.white.id ? `https://online-go.com/player/${players.white.id}/${players.white.username}` : '#'} className="Player">
                  <span className="Player-username">{players.white.username}</span>
                  <span className="Player-rank">[{players.white.rank ?? getUserRating(players.white).rank_label}]</span>
                </a>
              </span>
            </div>
          </div>
          {!!score && !!score.white && <div className="score-container">
            <div className="captures">
              <span className="num-captures-container">
                <span className="num-captures-count">{score.white.prisoners}</span>
                <span className="num-captures-units"> {t(score.white.prisoners === 1 ? 'capture' : 'captures')}</span>
              </span>
            </div>
            {!!score.white.komi && <div className="komi">
              +{score.white.komi}
            </div>}
          </div>}
        </div>
      </div>
    )}
    {!!kifu && <div className="d-flex justify-content-center">
      {!review && !existsReviewer && <Button className="mt-3" onClick={e => onCreateReview(e)}>{t('Create a Review')}</Button>}
      {!!reviews.length && <InputGroup className="mt-3">
        <InputGroup.Prepend>
          <InputGroup.Text><small>{t('Reviewer')}</small></InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control as="select" value={review?.id} onChange={e => onReviewSelect(e)}>
          <option value={''}>{t('None')}</option>
          {reviews.map((review: any) => (
            <option key={review.id} value={review.id}>{review?.user?.stageName || review?.user?.displayName}</option>
          ))}
        </Form.Control>
        {!!review && <Like objectId={review.id}>
          {(liked, handleLike) => (
            <InputGroup.Append>
              <Button variant={liked ? "primary" : "secondary"} onClick={e => handleLike(e, onLikeChange)}>
              {review?.totalLikes + totalLikes} <FontAwesomeIcon className={"mr-1 text-" + (liked ? "danger" : "default")} icon="heart" />
              </Button>
            </InputGroup.Append>
          )}
        </Like>}
      </InputGroup>}
    </div>}
  </>)
}
