import axios from 'axios'
import { ogsApiUrl } from '../configs'

export const getGameDetails = async (id) => {
  const { data } = await axios.get(`${ogsApiUrl}/api/v1/games/${id}`)
  return data
}

export const ogsDataToFirebaseData = data => {
  return {
    server: 'ogs',
    gamedata: JSON.stringify(data.gamedata),
    info: {
      players: data.players,
      width: data.width,
      height: data.height,
      handicap: data.handicap,
      komi: data.komi,
      outcome: data.outcome,
      black_lost: data.black_lost,
      white_lost: data.white_lost,
      started: data.started,
      ended: data.ended
    },
  }
}
