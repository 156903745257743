import { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { listFeed } from 'src/utils/api'
import InfiniteScroll from 'react-infinite-scroller'
import KifuPost from './KifuPost'
import PuzzlePost from './PuzzlePost'
import ReviewPost from './ReviewPost'

interface ActivityProps {
  filter?: any
}

export default function Feed({ filter }: ActivityProps): JSX.Element {
  const limit = 5
  const [feedItems, setFeedItems] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false)
  const [moreLoading, setMoreLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [include] = useState<any>([
    'user',
    {
      relation: 'puzzle',
      scope: {
        include: [{
          relation: 'puzzleAnswers',
          scope: {
            include: [{
              relation: 'user'
            }]
          }
        }]
      }
    },
    { relation: 'kifu' },
    { relation: 'review' },
  ])

  useEffect(() => {
    if (!filter) {
      return
    }
    let isSubscribed = true
    setLoading(true)
    listFeed({
      filter: {
        ...filter,
        limit,
        include
      }
    }).then(data => {
      if (!isSubscribed) {
        return
      }
      setPage(1)
      setLoading(false)
      setFeedItems(data)
      setHasMoreItems(data.length >= limit)
    })
    return () => {
      isSubscribed = false
    }
  }, [filter, include])

  const handleLoadMore = () => {
    if (moreLoading) {
      return
    }
    if (!feedItems.length) {
      return setHasMoreItems(false)
    }

    setMoreLoading(true)
    listFeed({
      filter: {
        ...filter,
        skip: page * limit,
        limit,
        include
      }
    }).then(async (data: any) => {
      if (data.length < limit) {
        setHasMoreItems(false)
      }
      setFeedItems([
        ...feedItems,
        ...data
      ])
      setMoreLoading(false)
      setPage(page + 1)
    })
  }

  return (
    <div className="position-relative">
      {loading && <div className="Loading-Container position-absolute">
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>}
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMoreItems}
        loader={<div key={feedItems.length} className="position-absolute Loading-More-Container">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      >
        {!!feedItems.length && feedItems.map(item => (
          <div key={item.id}>
            {item.type === 'kifu' && item.kifu && <KifuPost item={item} action={item.action} />}
            {item.type === 'review' && item.kifu && <ReviewPost item={item} action={item.action} />}
            {item.type === 'puzzle' && item.puzzle && <PuzzlePost item={item} />}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}
