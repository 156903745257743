export const apiUrl = process.env.REACT_APP_API_URL

// BOT API Url
export const botApiUrl = process.env.REACT_APP_BOT_API_URL

// Where to store game moves: 'firestore' or 'realtime'
export const gameMovesStorage = process.env.REACT_APP_GAME_MOVES_STORAGE

// Online-Go API Url
export const ogsApiUrl = process.env.REACT_APP_OGS_API_URL
