import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import firebase from "firebase/app"
import {useEffect, useState} from "react"
import {Card} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import {useHistory, useLocation} from "react-router-dom"
import blackStone from "../../assets/img/black.png"
import whiteStone from "../../assets/img/white.png"
import Div from "../Div"
import PuzzleGoban from "../ReactGoban/PuzzleGoban"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

interface PuzzleCardProps {
  puzzle?: any
}

export default function PuzzleCard({ puzzle }: PuzzleCardProps): JSX.Element {
  const query: any = useQuery()
  const history = useHistory()
  const [answered, setAnswered] = useState<boolean>(false)

  useEffect(() => {
    if (!puzzle) {
      return
    }
    const user = firebase.auth().currentUser
    if (puzzle?.puzzleAnswers?.find((answer: any) => answer.uid === user?.uid)) {
      setAnswered(true)
    }
  }, [puzzle])

  const handleClick = (e: any) => {
    e.preventDefault()
    history.push({
      pathname: '/puzzles/' + puzzle.id,
      search: query.toString()
    })
  }

  return (
    <Card className="mb-3">
      <Card.Body className="p-2 p-sm-3">
        <LinkContainer to={"/puzzles/" + puzzle.id}>
          <div className="Cursor-Pointer position-relative" onClick={handleClick}>
            <PuzzleGoban puzzle={puzzle} interactive={false} boardSquare />
            <Div className="Right-10 Bottom-10 position-absolute" show={answered}>
              <FontAwesomeIcon className="Font-Size-2em text-success" icon="check" />
            </Div>
          </div>
        </LinkContainer>
        <div className="mt-2 d-flex justify-content-between align-items-center">
          <span>
            {puzzle?.level >= 1 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
            {puzzle?.level >= 2 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
            {puzzle?.level >= 3 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
            {puzzle?.level >= 4 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
            {puzzle?.level >= 5 && <FontAwesomeIcon className={`Star ${puzzle?.opponentMoveMode !== 'manual' ? 'text-warning' : 'text-info'}`} icon="star" />}
          </span>
          <img className="Square-20 border rounded-circle" src={puzzle?.initialPlayer === 'black' ? blackStone : whiteStone} alt="" />
        </div>
      </Card.Body>
    </Card>
  )
}
