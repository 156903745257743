import 'firebase/database';
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Form
} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { registerUser } from 'src/utils/api';
import SubmitButton from 'src/components/SubmitButton';
import '../Login/style.scss'

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^[A-Za-z0-9_-]{5,24}$/, 'must match pattern "^[A-Za-z0-9_-]{3,24}$"')
    .min(5, 'must NOT have fewer than 5 characters')
    .max(24, 'must be at most 24 characters')
    .required('is a required field'),
  displayName: Yup.string()
    .min(6, 'must NOT have fewer than 6 characters')
    .required('is a required field'),
  email: Yup.string()
    .min(6, 'must NOT have fewer than 6 characters')
    .email('must be a valid email')
    .required('is a required field'),
  password: Yup.string()
    .min(6, 'must NOT have fewer than 6 characters')
    .required('is a required field'),
  confirmPassword: Yup.string()
    .min(6, 'must NOT have fewer than 6 characters')
    .oneOf([Yup.ref('password'), null], 'and password must match')
    .required('is a required field'),
})

function Register() {
  const { t } = useTranslation()
  const history = useHistory();

  const [formData, setFormData] = useState({
    username: '',
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({});

  const handleSetFormData = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err) {
      const errors = {}
      for (const error of err.inner) {
        error.path = '/' + error.path
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      const data = { ...formData }
      delete data.confirmPassword
      await registerUser(data)
    } catch (err) {
      let message = err.message
      if (err?.response?.data?.error?.details) {
        const errors = {}
        for (const error of err?.response?.data?.error?.details) {
          errors[error.path] = error
        }
        return setErrors(errors)
      }
      return setErrors({
        other: {
          path: 'other',
          message: err?.response?.data?.error?.message ?? message
        }
      })
    }
    history.push('/login');
  };

  return (
    <Container className="mt-5 md-5 login-container">
      <Row className="login-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="login-card">
            <Card.Body className="p-0">
              <Row>
                <Col className="d-none d-lg-block login-bg">
                </Col>
                <Col className="p-5">
                  <h2>{t('Create an Account')}</h2>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>{t('Email address')}</Form.Label>
                      <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t('Email address')} />
                      {errors?.['/email']?.message && (<Form.Text className="text-danger">{t(errors?.['/email']?.path)} {t(errors?.['/email']?.message)}</Form.Text>)}
                    </Form.Group>
                    <Form.Group controlId="formBasicUsername">
                      <Form.Label>{t('Username')}</Form.Label>
                      <Form.Control type="text" name="username" value={formData.username} onChange={handleSetFormData} placeholder={t('Username')} />
                      {errors?.['/username']?.message && (<Form.Text className="text-danger">{t(errors?.['/username']?.path)} {t(errors?.['/username']?.message)}</Form.Text>)}
                    </Form.Group>
                    <Form.Group controlId="formBasicDisplayName">
                      <Form.Label>{t('Display Name')}</Form.Label>
                      <Form.Control type="text" name="displayName" value={formData.displayName} onChange={handleSetFormData} placeholder={t('Display Name')} />
                      {errors?.['/displayName']?.message && (<Form.Text className="text-danger">{t(errors?.['/displayName']?.path)} {t(errors?.['/displayName']?.message)}</Form.Text>)}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>{t('Password')}</Form.Label>
                      <Form.Control type="password" name="password" value={formData.password} onChange={handleSetFormData} placeholder={t('Password')} />
                      {errors?.['/password']?.message && (<Form.Text className="text-danger">{t(errors?.['/password']?.path)} {t(errors?.['/password']?.message)}</Form.Text>)}
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                      <Form.Label>{t('Confirm Password')}</Form.Label>
                      <Form.Control type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleSetFormData} placeholder={t('Password')} />
                      {errors?.['/confirmPassword']?.message && (<Form.Text className="text-danger">{t(errors?.['/confirmPassword']?.path)} {t(errors?.['/confirmPassword']?.message)}</Form.Text>)}
                      {errors?.other?.message && (<Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text>)}
                    </Form.Group>
                    <SubmitButton variant="success" type="submit" onClick={handleSubmit} block>
                      {t('Register')}
                    </SubmitButton>
                    <hr/>
                    <div className="text-center">
                      <Link to="/forgot-password">{t('Forgot password?')}</Link>
                    </div>
                    <div className="text-center">
                      <Link to="/login">{t('Already have an account? Login!')}</Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Register;
