import firebase from "firebase/app"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Image, ListGroup } from "react-bootstrap"
import { getDefaultAvatar } from "src/models/UserModel"
import { useTranslation } from "react-i18next"
import { LinkContainer } from "react-router-bootstrap"

export default function WelcomeGuest(): JSX.Element {
  const { t } = useTranslation()
  const user: firebase.User | null = firebase.auth().currentUser

  if (user) {
    return <></>
  }

  return (
    <Card className="User-Info-Container mb-3 overflow-hidden">
      <ListGroup variant="flush">
        <ListGroup.Item className="User-Info text-center border-0">
          <div className="Background bg-dark"></div>
          <div className="Avatar-Container">
            <Image thumbnail className="w-100" src={getDefaultAvatar('guest', '')} roundedCircle />
            <Image thumbnail className="Avatar" src={getDefaultAvatar('guest', '')} roundedCircle />
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="text-center pt-0">
          <h3>{t('Welcome To Kifu')}</h3>
        </ListGroup.Item>
        <ListGroup.Item>
          <LinkContainer to="/login">
            <Button className="d-flex justify-content-between align-items-center" variant="success" block>
              <FontAwesomeIcon icon="lock" className="mr-3" />
              {t('Login')}
              <span className="mx-3">&nbsp;</span>
            </Button>
          </LinkContainer>
        </ListGroup.Item>
        <ListGroup.Item>
          <LinkContainer to="/register">
            <Button className="d-flex justify-content-between align-items-center" variant="success" block>
              <FontAwesomeIcon icon="user-plus" className="mr-3" />
              {t('Register')}
              <span className="mx-3">&nbsp;</span>
            </Button>
          </LinkContainer>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  )
}
