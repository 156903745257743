import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'
import PropTypes from 'prop-types'
import ReactResizeDetector from 'react-resize-detector'
import { IfFirebaseUnAuthed, IfFirebaseAuthed } from '@react-firebase/auth'
import { useEffect, useRef, useState } from 'react'
import { Card, Form, ListGroup, Badge, InputGroup, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { ArrowReturnLeft } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { User } from '../Model'

function ChatMarkup(body, extraPatternReplacements, lengthLimit) {
  let replacements = []
  if (!lengthLimit) {
    lengthLimit = 1024
  }
  if (body.length > lengthLimit) {
    return [<span key="message-too-long">&lt;{'Message too long'}&gt;</span>]
  }
  if (extraPatternReplacements) {
    replacements = replacements.concat(extraPatternReplacements)
  }
  let fragments = [body]
  for (let r of replacements) {
    fragments = [].concat.apply([], fragments.map((text_fragment) => {
      return text_fragment.split(r.split)
    }))
  }
  fragments = fragments.filter((value) => (value !== ''))
  return fragments.map((fragment, i) => {
    for (let r of replacements) {
      let m = r.pattern.exec(fragment)
      if (m) {
        return r.replacement(m, i)
      }
    }
    return <span key={i}>{fragment}</span>
  });
}

function Chat ({ reviewOwnerId, messages, onChat, onMoveJump, onHoverVariation, onLeaveVariation, onClickVariation, onHoverPositionText, onLeavePositionText }) {
  const { t } = useTranslation()
  const refChatItems = useRef(null)
  const [message, setMessage] = useState('')
  const [scrolledToBottom, setScrolledToBottom] = useState(true)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (!scrolledToBottom) {
      return
    }
    refChatItems.current.scrollTop = refChatItems.current.scrollHeight
    const timeout = setTimeout(() => {
      if (refChatItems.current) {
        refChatItems.current.scrollTop = refChatItems.current.scrollHeight
      }
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [scrolledToBottom, messages])

  const handleResize = () => {
    if (!scrolledToBottom) {
      return
    }
    refChatItems.current.scrollTop = refChatItems.current.scrollHeight
    setTimeout(() => {
      if (refChatItems.current) {
        refChatItems.current.scrollTop = refChatItems.current.scrollHeight
      }
    }, 100)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const user = firebase.auth().currentUser
    if (!user) {
      return setMessage('Please login to send message')
    }
    if (!message) {
      return
    }
    onChat(message)
    setMessage('')
  }

  const updateScrollPosition = () => {
    const tf = refChatItems.current.scrollHeight - refChatItems.current.scrollTop - 30 < refChatItems.current.offsetHeight
    let newValue
    if (tf !== scrolledToBottom) {
      newValue  = tf
      refChatItems.current.className = 'p-0 Chat-Items ' + (tf ? 'autoscrolling' : '')
    }
    newValue = refChatItems.current.scrollHeight - refChatItems.current.scrollTop - 30 < refChatItems.current.offsetHeight
    setScrolledToBottom(newValue)
  }

  const renderBody = body => {
    if (typeof(body) === 'string') {
      return ChatMarkup(body, [{
        split: /(\b[a-zA-Z][0-9]{1,2}\b)/mg, pattern: /\b([a-zA-Z][0-9]{1,2})\b/mg,
        replacement: (m, idx) => {
          return (<span key={idx} className="position" onMouseEnter={() => onHoverPositionText(m[1])} onMouseLeave={() => onLeavePositionText(m[1])}>{m[1]}</span>)
        }
      }])
    }
    switch (body.type) {
      case 'analysis':
        return (
          <span className="variation"
            onMouseEnter={() => onHoverVariation(body)}
            onMouseLeave={() => onLeaveVariation()}
            onClick={() => onClickVariation(body)}
          >
            {('Biến: ') + (body.name ? body.name : '<error>')}
          </span>
        )
      case 'review':
        return (
          <Link to={`/reviews/${body.reviewId}`}>đã tạo review</Link>
        )
      default:
    }
  }

  return (
    <Card className="p-2 vh-100">
      <Card.Body ref={refChatItems} className="p-0 Chat-Items autoscrolling" onScroll={updateScrollPosition}>
        {loaded && <ReactResizeDetector handleWidth handleHeight onResize={() => handleResize()} targetDomEl={refChatItems.current || undefined}/>}
        <ListGroup variant="flush">
          {messages.map((message, index) => (
            <div key={index} className="px-0">
              {index > 0 && messages[index - 1].from === message.from &&
                messages[index - 1].moves === message.moves ? null :
                <div className="LineText move-number" onClick={() => onMoveJump(message)}>
                  <span className="left"></span>
                  <span className="contents">Move {
                    ('move_number' in message
                      ? message.move_number
                      : ('moves' in message ? (message.from + (message.moves.length ? ' + ' + message.moves.length / 2 : '')) : '')
                    )
                  }</span>
                  <span className="right"></span>
                </div>
              }
              <User uid={message.user.uid}>
                {(user, ready) => <><strong>
                  {message.body?.type === 'review' || message.user.uid === reviewOwnerId ? (user.stageName || (user.displayName || message.user.displayName)) : (user.displayName || message.user.displayName)}
                </strong>{' '}</>}
              </User>
              {renderBody(message.body)}{' '}
              <Badge variant="light" className="Time">{moment(message.createdAt).fromNow()}</Badge>
            </div>
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Body className="py-0 px-2 Chat-Input">
        <IfFirebaseAuthed>
          {() => (
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Form.Control type="text" value={message} onChange={e => setMessage(e.target.value)} placeholder={t("Enter chat message")} required />
                <InputGroup.Append>
                  <Button type="submit" className="mb-2">
                    <ArrowReturnLeft />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          )}
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          {() => (
            <Form className="mb-2">
              <Form.Control type="text" placeholder={t("Login to chat")} required disabled />
            </Form>
          )}
        </IfFirebaseUnAuthed>
      </Card.Body>
    </Card>
  )
}

Chat.propTypes = {
  reviewOwnerId: PropTypes.string,
  messages: PropTypes.array,
  onMoveJump: PropTypes.func,
  onHoverVariation: PropTypes.func,
  onLeaveVariation: PropTypes.func,
  onClickVariation: PropTypes.func,
  onHoverPositionText: PropTypes.func,
  onLeavePositionText: PropTypes.func,
  onChat: PropTypes.func
}

Chat.defaultProps = {
  reviewOwnerId: '',
  messages: [],
  onMoveJump: () => {},
  onHoverVariation: () => {},
  onLeaveVariation: () => {},
  onClickVariation: () => {},
  onHoverPositionText: () => {},
  onLeavePositionText: () => {},
  onChat: () => {}
}

export default Chat
