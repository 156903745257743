import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import "react-placeholder/lib/reactPlaceholder.css";
import 'react-toastify/dist/ReactToastify.css';
import firebaseConfig from './configs/firebase';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './style.scss';
import i18nVi from './translations/vi.json';


// Install font awesome
library.add(fas)

// Init i18n
i18n.use(initReactI18next).init({
  resources: {
    vi: {
      translation: i18nVi
    }
  },
  lng: 'vi',
  fallbackLng: 'vi',
  interpolation: {
    escapeValue: false
  }
})
moment.locale('vi')

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <App />
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
